/*   OnlineOpinion v5.10.4 Released: 3/30/2020. Compiled 04/23/2021 02:54:26 PM -0400 Branch: 5.10.4 4fc68e960aa79de5cdea896ed090d42ea8961e3b Components: Full UMD: disabled The following code is Copyright 1998-2021 Opinionlab, Inc. All rights reserved. Unauthorized use is prohibited. This product and other products of OpinionLab, Inc. are protected by U.S. Patent No. 6606581, 6421724, 6785717 B1 and other patents pending. http://www.opinionlab.com    */ (function (
  a,
  b
) {
  if ('disabled' === 'enabled' && typeof define === 'function' && define.amd) {
    define([], b);
  } else {
    a.OOo = b();
  }
})(this, function () {
  window.OOo = {
    __detectBrowser: function (b) {
      var c = Object.prototype.toString.call(window.opera) === '[object Opera]',
        f = b.indexOf('MSIE ') > -1 || b.indexOf('Trident/') > -1,
        d = {
          IE: !!f,
          MSEdge: b.indexOf('Edge/') > -1,
          Opera: c,
          WebKit: b.indexOf('AppleWebKit/') > -1,
          Chrome: b.indexOf('Chrome') > -1 && b.indexOf('Edge/') === -1,
          Gecko: b.indexOf('Gecko') > -1 && b.indexOf('KHTML') === -1,
          MobileSafari: /Apple.*Mobile.*Safari/.test(b),
          iOs:
            b.indexOf('iPad') > -1 ||
            b.indexOf('iPhone') > -1 ||
            b.indexOf('iPod') > -1,
          iOS67: b.search(/OS 6(.*)|7(.*) like Mac OS/i) > -1,
          BlackBerry: b.indexOf('BlackBerry') > -1,
          Fennec: b.indexOf('Fennec') > -1,
          Safari:
            /constructor/i.test(window.HTMLElement) ||
            (function (a) {
              return a.toString() === '[object SafariRemoteNotification]';
            })(!window.safari || safari.pushNotification),
          IEMobile: b.indexOf('IEMobile') > -1,
          WindowsPhone: b.toLowerCase().indexOf('windows phone') > -1,
          WindowsTablet:
            b.toLowerCase().indexOf('windows nt') > -1 &&
            b.toLowerCase().indexOf('touch') > -1,
          OperaMobile: b.search(/Opera (?:Mobi|Mini)/) > -1,
          Kindle: b.search(/[ ](Kindle|Silk)/) > -1,
          isChromeIOS: /crios/i.test(b),
          ua: b,
          Android: /Android/.test(b),
          facebookWebview: /FBAV/.test(b),
          googleWebview: /GSA/.test(b),
          AndroidChromiumWebview: /Chrome\/.* Mobile/.test(b),
          iOsWebview: /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/.test(b),
        },
        g = false;
      d.isMobile =
        d.MobileSafari ||
        d.BlackBerry ||
        d.Fennec ||
        d.IEMobile ||
        d.OperaMobile ||
        d.Kindle ||
        d.iOs ||
        d.Android ||
        d.WindowsPhone ||
        d.WindowsTablet ||
        d.AndroidChromiumWebview ||
        d.iOsWebview ||
        d.facebookWebview ||
        d.googleWebview;
      d.isDesktop = !d.isMobile;
      d.isMobileNonIOS = d.isMobile && !d.iOs;
      d.isSafariDesktop = d.Safari && !d.isMobile;
      return d;
    },
  };
  OOo.Browser = OOo.__detectBrowser(navigator.userAgent);
  OOo.Cache = {};
  OOo.instanceCount = 0;
  OOo.K = function () {};
  OOo.version = '5.10.4';
  OOo.restoreArray = [];
  OOo.fcp = true;
  OOo.spa = true;
  OOo.useBrowserStorage = false;
  var bk = bk || OOo;
  (function () {
    function bl(a) {
      return document.querySelector(a);
    }
    function bm(a) {
      return document.querySelectorAll(a);
    }
    function Y(a, b) {
      var c;
      for (c in b) {
        if (b.hasOwnProperty(c)) {
          a[c] = b[c];
        }
      }
      return a;
    }
    function bn(a, b, c, f) {
      if (a.addEventListener) {
        a.addEventListener(b, c, f);
      } else if (a.attachEvent) {
        a.attachEvent('on' + b, c);
      }
    }
    function bo(a, b, c, f) {
      if (a.removeEventListener) {
        a.removeEventListener(b, c, f);
      } else if (a.detachEvent) {
        a.detachEvent('on' + b, c);
      }
    }
    function Z() {
      if (typeof Object.assign !== 'function') {
        Object.defineProperty(Object, 'assign', {
          value: function k(a, b) {
            'use strict';
            if (a === null || a === undefined) {
              throw new TypeError('Cannot convert undefined or null to object');
            }
            var c = Object(a);
            for (var f = 1; f < arguments.length; f++) {
              var d = arguments[f];
              if (d !== null && d !== undefined) {
                for (var g in d) {
                  if (Object.prototype.hasOwnProperty.call(d, g)) {
                    c[g] = d[g];
                  }
                }
              }
            }
            return c;
          },
          writable: true,
          configurable: true,
        });
      }
    }
    function bp(a) {
      var b = document.querySelector(
        "script[data-vendor='fs'][data-role='gateway']"
      );
      if (!b) {
        return false;
      } else {
        var c;
        if (
          b.hasAttribute('data-isselfhosted') &&
          b.getAttribute('data-isselfhosted')
        ) {
          c = true;
        }
        var f = b.src ? b.src : null;
        if (!f) {
          return false;
        } else {
          var d = /gateway\.foresee\.com/g,
            g = /\/\/[A-Z]{1,3}|[a-z]{1,3}-/g,
            k;
          OOo.fcpEnvironment;
          if (d.test(f)) {
            if (g.test(f)) {
              k = OOo.fcpEnvironment = f.slice(f.search(g), f.search(d));
            } else {
              k = OOo.fcpEnvironment = '';
            }
            if (a && a === 'sites') {
              var h = /sites\/(.*)\//g;
              var o = f.split(h)[1];
              var m = o.split('/');
              return [m, k];
            } else if (a && a === 'code') {
              return [
                OOo ? (OOo.version ? OOo.version + '-oo' : '5.10.3-oo') : '',
                k,
              ];
            } else if (a && a === 'self-hosted') {
              return c;
            }
          } else {
            return false;
          }
        }
      }
    }
    function L(a, b) {
      var c = './onlineopinionV5/',
        f;
      if (a) {
        if (b == 'custom') {
          var d = OOo.checkGateway('sites');
          if (d) {
            var g = d[0][0],
              k = d[0][1];
            f = d[1] ? d[1] : '';
            c =
              'https://' +
              f +
              'gateway.foresee.com/sites/' +
              g +
              '/' +
              k +
              '/opinionlab/';
          }
        } else if (b == 'static') {
          var h = OOo.checkGateway('code');
          if (h) {
            codeVersion = h[0];
            f = h[1];
            c =
              'https://' + f + 'gateway.foresee.com/code/' + codeVersion + '/';
          }
        }
      }
      return c;
    }
    function N(a) {
      var b = [],
        c;
      for (c in a) {
        if (a.hasOwnProperty(c)) {
          b.push(c + '=' + (encodeURIComponent(a[c]) || ''));
        }
      }
      return b.join('&');
    }
    function O(c) {
      if (c) {
        OOo.observer.disconnect();
      } else {
        var f = document.body,
          d = { subtree: false, childList: true },
          g = function (a, b) {
            if (a && a[0].addedNodes && a[0].addedNodes.length > 0) {
              G(a[0].addedNodes);
            }
          };
        if (OOo.observer == undefined) {
          OOo.observer = new MutationObserver(g);
          OOo.observer.observe(f, d);
        } else {
          OOo.observer.observe(f, d);
        }
      }
    }
    function F() {
      return document ? (document.body ? document.body.children : '') : '';
    }
    function G(a) {
      P(a, 'hide');
      O();
    }
    function P(a, b) {
      if (a.length === 0) {
        return;
      }
      if (b === 'show') {
        for (var c = 0; c < a.length; c++) {
          a[c].removeAttribute('aria-hidden');
        }
        for (var f = 0; f < OOo.restoreArray.length; f++) {
          OOo.restoreArray[f].el.setAttribute(
            'aria-hidden',
            OOo.restoreArray[f].ariahidden
          );
        }
      } else if (b === 'hide') {
        for (var c = 0; c < a.length; c++) {
          if (a[c].nodeName === 'SCRIPT' || a[c].nodeName === 'STYLE') {
            continue;
          }
          if (a[c].getAttribute && a[c].getAttribute('aria-hidden')) {
            OOo.restoreArray.push({
              el: a[c],
              ariahidden:
                a[c].getAttribute('aria-hidden') == 'true' ? 'true' : 'false',
            });
          }
          if (
            a[c].getAttribute &&
            (a[c].getAttribute('id') == 'oo_waypoint_container' ||
              a[c].getAttribute('id') == 'oo_invitation_prompt' ||
              a[c].getAttribute('id') == 'oo_container' ||
              a[c].getAttribute('id') == 'oo_entry_prompt' ||
              (a[c].getAttribute('class') &&
                a[c].getAttribute('class').search('oo_cc_wrapper') > -1))
          ) {
            continue;
          } else {
            a[c].setAttribute('aria-hidden', 'true');
          }
        }
      }
    }
    function Q(a) {
      P(a, 'show');
      O(true);
      OOo.restoreArray = [];
    }
    function ba(a) {
      var b = /[\(\)']+/g;
      newFnArgs = a[0].replace(b, '');
      return newFnArgs;
    }
    function bb(a, b) {
      var c = /\(.*?\)/;
      var f = c.exec(b[0]);
      f = ba(f);
      f = bc(f);
      var d = b[0].replace(c, '');
      return bd(a, d, f);
    }
    function bc(a) {
      if (Array.isArray(a)) {
        var b = a[0];
      } else {
        var b = a;
      }
      var c = b.split(',');
      for (var f = 0; f < c.length; f++) {
        c[f] = c[f].replace(/[\(\)']+/g, '');
        if (c[f] === 'true') {
          c[f] = true;
        } else if (c[f] === 'false') {
          c[f] = false;
        } else if (c[f] == parseInt(c[f])) {
          c[f] = parseInt(c[f], 10);
        } else if (c[f] === '') {
          c.pop(f);
        }
      }
      return c;
    }
    function bd(d, g, k) {
      var h;
      function o(a) {
        return l(a) || n(a) || q(a) || m();
      }
      function m() {
        throw new TypeError(
          'Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.'
        );
      }
      function q(a, b) {
        if (!a) return;
        if (typeof a === 'string') return p(a, b);
        var c = Object.prototype.toString.call(a).slice(8, -1);
        if (c === 'Object' && a.constructor) c = a.constructor.name;
        if (c === 'Map' || c === 'Set') return Array.from(c);
        if (
          c === 'Arguments' ||
          /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(c)
        )
          return p(a, b);
      }
      function n(a) {
        if (typeof Symbol !== 'undefined' && Symbol.iterator in Object(a))
          return Array.from(a);
      }
      function l(a) {
        if (Array.isArray(a)) return p(a);
      }
      function p(a, b) {
        if (b == null || b > a.length) b = a.length;
        for (var c = 0, f = new Array(b); c < b; c++) {
          f[c] = a[c];
        }
        return f;
      }
      return (h = d)[g].apply(h, o(k));
    }
    function M(a, b) {
      if (b.length !== 0 && b[0].search(/\(.*?\)/) >= 0) {
        var c = bb(a, b);
        return M(c, b.slice(1));
      } else {
        return b.length ? M(a[b[0]], b.slice(1)) : a;
      }
    }
    function R(a) {
      if (typeof a == 'object') {
        return a;
      } else if (typeof a == 'function') {
        a = a();
        return a;
      }
    }
    function S(a) {
      var b;
      if (a) {
        b = Object.keys(a);
      }
      return b;
    }
    function T(b) {
      var c;
      if (b) {
        c = Object.keys(b).map(function (a) {
          return b[a];
        });
      }
      return c;
    }
    function be(a) {
      try {
        var b = M(window, a);
        return typeof b !== 'undefined' ? b : '';
      } catch (e) {
        return '';
      }
    }
    function bq(a) {
      if (!a.customVariables && !a.efm && !a.digitalBehaviorAnalytics) {
        return null;
      } else if (a.customVariables || a.efm || a.digitalBehaviorAnalytics) {
        var b = {},
          c = {},
          f,
          d = a.customVariables ? R(a.customVariables) : {};
        for (key in d) {
          if (d.hasOwnProperty(key)) {
            var g =
              a.customVariables.nestedVariables ||
              (typeof a.customVariables === 'function' &&
                a.customVariables().nestedVariables)
                ? R(a.customVariables).nestedVariables
                : undefined;
          }
        }
        if (a.digitalBehaviorAnalytics) {
          if (
            window.Mpathy &&
            typeof window.Mpathy.getIntegrationData === 'function'
          ) {
            if (typeof window.Mpathy.getIntegrationData() === 'object') {
              f = Mpathy.getIntegrationData();
              d.digitalBehaviorAnalyticsSite = f.idsite;
              d.digitalBehaviorAnalyticsPage = f.idpage;
              d.digitalBehaviorAnalyticsInsightUrl = f.insightUrl;
              d.digitalBehaviorAnalyticsReplayUrl = f.replayUrl;
            }
          }
        }
        if (a.efm) {
          d.EFMSurvey = true;
        }
        if (a.efmWindowSize) {
          d.EFMWidth = a.efmWindowSize[0];
          d.EFMHeight = a.efmWindowSize[1];
        }
        if (g) {
          delete d.nestedVariables;
        }
        var k = d ? S(d) : undefined;
        var h = d ? T(d) : undefined;
        var o = g ? S(g) : undefined;
        var m = g ? T(g) : undefined;
        var q = m ? br(m, window) : undefined;
        b = k && h ? bf(k, h, b) : undefined;
        c = o && q ? bf(o, q, c) : undefined;
        Z();
        c ? Object.assign(b, c) : undefined;
        return b;
      }
    }
    function br(a, b) {
      for (var c = 0; c < a.length; c++) {
        if (Array.isArray(a[c])) {
          a[c] = E(be(a[c], b));
        }
      }
      return a;
    }
    function bf(a, b, c) {
      for (var f = 0; f < a.length; f++) {
        c[a[f]] = E(b[f]);
      }
      return c;
    }
    function U(a, b) {
      var c = '';
      for (var f = 0; f < b.length; f++) {
        var d = false;
        for (var g = 0; g < a.length; g++) {
          if (b[f].indexOf(a[g]) > -1) {
            d = true;
            break;
          }
        }
        if (!d) {
          c += b[f];
          if (f !== b.length - 1) {
            c += '&';
          }
        }
      }
      return c;
    }
    function V(a) {
      if (typeof a === 'number') {
        var b = new Date(a);
        var c = b.getHours(),
          f = b.getMinutes();
        var d =
          b.toString().split(' ')[1] +
          ' ' +
          b.getDate() +
          ', ' +
          b.getFullYear();
        var g = 'AM';
        if (c >= 12) {
          g = 'PM';
        }
        if (c === 0) {
          c = 12;
        } else if (c > 12) {
          c = c - 12;
        }
        f = f < 10 ? '0' + f : f;
        var k = c + ':' + f + ' ' + g;
        return d + ' ' + k;
      } else {
        return a;
      }
    }
    function bg(a, b) {
      var c = N(b);
      var f = c.split('&');
      c = U(['width', 'height'], f);
      f = c.split('&');
      c = U(['time1', 'time2'], f);
      c +=
        '&timeOnPage=' +
        encodeURIComponent(b.time2 - b.time1) +
        '&resolution=' +
        encodeURIComponent(b.width + ' \u00D7 ' + b.height) +
        '&time1=' +
        encodeURIComponent(V(b.time1)) +
        '&time2=' +
        encodeURIComponent(V(b.time2));
      if (
        typeof a.customVariables !== 'undefined' ||
        a.digitalBehaviorAnalytics
      ) {
        var d = OOo.evalCustomVars(a);
        for (var g in d) {
          if (g == 'EFMSurvey' || g == 'EFMWidth' || g == 'EFMHeight') {
            continue;
          }
          c += '&' + g + '=' + encodeURIComponent(OOo.serialize(d[g]));
        }
      }
      return c;
    }
    function W(a) {
      var b, c, f, d, g, k;
      if (a.efm) {
        b = bg(a, a.metrics);
      } else {
        b = N(a.metrics);
        c = typeof a.tealeafId !== 'undefined' ? E(a.tealeafId) : undefined;
        f =
          typeof a.clickTalePID !== 'undefined' ? E(a.clickTalePID) : undefined;
        d =
          typeof a.clickTaleUID !== 'undefined' ? E(a.clickTaleUID) : undefined;
        g =
          typeof a.clickTaleSID !== 'undefined' ? E(a.clickTaleSID) : undefined;
        k = a.legacyVariables;
        var h = c + '|' + f + '/' + d + '/' + g;
        b += '&custom_var=' + OOo.createLegacyVars(k, h);
        if (a.metrics.type === 'OnPage') {
          b += '|iframe';
        }
        if (a.asm) {
          b += '&asm=2';
        }
        b += '&_' + 'rev=2';
        if (a.customVariables || a.digitalBehaviorAnalytics) {
          customVars = OOo.evalCustomVars(a);
          b += '&customVars=' + encodeURIComponent(OOo.serialize(customVars));
        }
      }
      return b;
    }
    function bs(a, b) {
      var c = a.referrerRewrite;
      a.metrics.referer = location.href;
      a.metrics.prev = encodeURIComponent(document.referrer);
      if (c) {
        a.metrics.referer = OOo.referrerRewrite(c);
      }
      if (a.constructCommentCardUrl) {
        var f = 'https://secure.opinionlab.com/ccc01/comment_card_d.asp';
        if (a.commentCardUrl) {
          f = a.commentCardUrl;
        }
        f += '?' + W(a);
        return f;
      } else {
        var d = document,
          g = d.createElement('form'),
          k = d.createElement('input');
        g.style.display = 'none';
        g.method = 'post';
        g.target = b || 'OnlineOpinion';
        g.action = 'https://secure.opinionlab.com/ccc01/comment_card_d.asp';
        if (a.commentCardUrl) {
          g.action = a.commentCardUrl;
        }
        k.name = 'params';
        k.value = W(a);
        g.appendChild(k);
        d.body.appendChild(g);
        return g;
      }
    }
    function bt() {
      return {
        width: screen.width,
        height: screen.height,
        referer: location.href,
        prev: document.referrer,
        time1: new Date().getTime(),
        time2: null,
        currentURL: location.href,
        ocodeVersion: '5.10.4',
      };
    }
    function bu(a) {
      var b = '';
      if (a && a.search('://') > -1) {
        var c = a.split('/');
        for (var f = 3; f < c.length; f++) {
          b += '/';
          b += c[f];
        }
      }
      return b;
    }
    function E(a) {
      if (typeof a === 'object' && a != null) {
        for (var b = 0; b < a.length; b++) {
          a[b] = typeof a[b] === 'string' ? a[b].replace(/[,<>"']/g, '') : a[b];
        }
        return a;
      } else if (typeof a === 'string') {
        a = a.replace(/[,<>"']/g, '');
        return a;
      } else {
        return a;
      }
    }
    function bv(a, b) {
      a = a || {};
      if (typeof a === 'string') {
        return b + '|' + E(a);
      }
      return a.override ? E(a.vars) : b + (a.vars ? '|' + E(a.vars) : '');
    }
    function bw(a, b) {
      if (!b) {
        b = location;
      }
      if (typeof a === 'string') return a;
      return a.searchPattern
        ? b.href.replace(a.searchPattern, a.replacePattern)
        : a.replacePattern;
    }
    var bx = (function () {
      if (
        navigator.appName === 'Microsoft Internet Explorer' &&
        navigator.userAgent.search('MSIE 6') !== -1
      ) {
        return true;
      }
      var a = document.body,
        b,
        c;
      if (document.createElement && a && a.appendChild && a.removeChild) {
        b = document.createElement('iframe');
        b.setAttribute(
          'sandbox',
          'allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
        ),
          (c = false);
        b.setAttribute('name', 'oo_test');
        b.style.display = 'none';
        a.appendChild(b);
        c = !!!document.getElementsByName('oo_test')[0];
        a.removeChild(b);
        return c;
      } else {
        return null;
      }
    })();
    function by(a, b) {
      if (typeof a !== 'undefined' && a.length > 0) {
        for (var c = 0; c < a.length; c++) {
          if (document.querySelector(a[c])) {
            return document.querySelector(a[c]);
          }
        }
      }
      return b;
    }
    function bz(a, b) {
      var c = a || window.event,
        f = OOo.$('#oo_waypoint_content'),
        d = OOo.$('#oo_waypoint_company_logo'),
        g = c.target;
      if (c.srcElement && !c.target) {
        g = c.srcElement;
      }
      if (c.preventDefault && c.stopPropagation) {
        c.preventDefault();
        c.stopPropagation();
      } else {
        c.returnValue = false;
      }
      if (
        (g !== f && !f.contains(g) && g !== d && !d.contains(g)) ||
        g.className === 'waypoint_icon' ||
        g.className === 'waypoint_icon last'
      ) {
        var k = OOo.$('#oo_waypoint_container'),
          h = k.parentNode;
        Q(F());
        h.removeChild(k);
        if (b) {
          b.focus();
        }
      }
    }
    function bA() {
      var a = this.options;
      var b =
        "<div id='oo_waypoint_prompt' role='dialog' aria-modal='true' aria-describedby='oo_waypoint_message'></div>";
      if (typeof a.wpmarkup !== 'undefined') {
        b = a.wpmarkup;
      }
      var c = OOo.$('#oo_waypoint_prompt');
      if (c) {
        var f = OOo.$('#oo_waypoint_container');
        G(F());
        this.showWaypoint(f);
        return;
      }
      G(F());
      this.showWaypoint(b);
    }
    function bB(f) {
      var d = document,
        g = 0,
        k = typeof f === 'string' ? d.createElement('div') : f,
        h = d.createElement('div'),
        o,
        m,
        q,
        n = this.options,
        l = OOo ? (OOo.fcp ? OOo.fcp : false) : false,
        p = n ? (n.assetType ? n.assetType : 'custom') : '',
        u,
        r,
        t = n.categories,
        x,
        v,
        s = d.activeElement,
        A = n.screenReader ? n.screenReader : 'This will open a new window',
        B,
        u = n.pathToAssets ? n.pathToAssets : L(l, p);
      h.id = 'oo_waypoint_overlay';
      k.id = 'oo_waypoint_container';
      k.style.visibility = 'hidden';
      if (typeof f === 'string') {
        k.innerHTML = f;
        d.body.appendChild(k);
      }
      k.appendChild(h);
      if (n.companyLogo && n.companySlogan) {
        var z = n.companyLogo.split('/');
        var C = z[z.length - 1];
        o = new Image();
        o.onerror = function () {
          this.parentNode.removeChild(this);
        };
        o.src = u + C;
        o.alt = n.companySlogan;
        OOo.$('#oo_waypoint_company_logo').appendChild(o);
      }
      n.companySlogan
        ? OOo.$('#oo_waypoint_prompt').setAttribute(
            'aria-label',
            n.companySlogan
          )
        : 'We value your opinion!';
      footerBranding = document.createElement('p');
      footerBranding.innerText = 'Powered by Verint Experience Cloud';
      r = OOo.$('#ol_waypoint_brand_logo');
      r.appendChild(footerBranding);
      x = OOo.$('#oo_waypoint_close_prompt');
      B = x.children[0];
      B.innerHTML = n.screenReaderClose ? n.screenReaderClose : 'Close Dialog';
      for (var w in t) {
        if (t.hasOwnProperty(w)) {
          var y = document.createElement('button'),
            D = n.categories[w].icon,
            H = n.categories[w].buttonText,
            I;
          y.className = 'waypoint_icon';
          y.setAttribute('tabindex', '0');
          y.innerHTML =
            '<span id="icon_text">' +
            H +
            '</span><span class="screen_reader">' +
            A +
            '</span>';
          if (D && d.addEventListener) {
            I = document.createElement('IMG');
            I.setAttribute('class', 'waypoint_link_icon');
            I.onerror = function () {
              this.parentNode.removeChild(this);
            };
            u = t[w].assetType ? L(l, t[w].assetType) : u;
            I.setAttribute('src', u + D);
            y.appendChild(I);
          }
          if (typeof n.categories[w].oCode === 'string') {
            OOo.addEventListener(
              y,
              'click',
              (function (c) {
                return function (a) {
                  var b = a || window.event;
                  window.open(
                    n.categories[c].oCode,
                    '_0',
                    'scrollbars=yes,location=yes,menubar=yes,resizable=yes'
                  );
                  OOo.hideWaypoint(b, s);
                };
              })(w),
              false
            );
            OOo.addEventListener(
              y,
              'keydown',
              (function (c) {
                return function (a) {
                  var b = a || window.event;
                  if (b.keyCode !== 13) {
                    return;
                  }
                  window.open(
                    n.categories[c].oCode,
                    '_0',
                    'scrollbars=yes,location=yes,menubar=yes,resizable=yes'
                  );
                  OOo.hideWaypoint(b, s);
                };
              })(w),
              false
            );
          } else {
            OOo.addEventListener(
              y,
              'click',
              (function (c) {
                return function (a) {
                  var b = a || window.event;
                  OOo.Waypoint[c].show(b);
                  OOo.hideWaypoint(b, s);
                };
              })(w),
              false
            );
            OOo.addEventListener(
              y,
              'keydown',
              (function (c) {
                return function (a) {
                  var b = a || window.event;
                  if (b.keyCode !== 13) {
                    return;
                  }
                  OOo.Waypoint[c].show(b);
                  OOo.hideWaypoint(b, s);
                };
              })(w),
              false
            );
          }
          g++;
          OOo.$('#waypoint_icons').appendChild(y);
        }
      }
      OOo.addEventListener(
        k,
        'click',
        function (a) {
          var b = a || window.event;
          a.target != prompt ? OOo.hideWaypoint(b, s) : null;
        },
        false
      );
      q = OOo.$('#waypoint_icons').children;
      v = q[0];
      OOo.ooTrapFocus(OOo.$('#oo_waypoint_prompt'));
      OOo.addEventListener(
        x,
        'keydown',
        function (a) {
          var b = a || window.event;
          if (b.keyCode !== 13) {
            return;
          }
          OOo.hideWaypoint(b, s);
        },
        false
      );
      OOo.addEventListener(
        k,
        'keydown',
        function (a) {
          var b = a || window.event;
          if (b.key === 'Escape' || b.key === 'Esc') {
            OOo.hideWaypoint(b, s);
          } else {
            return;
          }
        },
        false
      );
      q[g - 1].className = 'waypoint_icon last';
      k.style.visibility = 'visible';
      k.style.display = 'block';
      h.className = 'no_loading';
      bh(n, v);
    }
    function bh(a, b) {
      if (a.linkFocus === true) {
        b.focus();
      }
    }
    function bi() {
      var a = OOo.$('#oo_container');
      if (!!a) {
        document.body.removeChild(a);
      }
    }
    function bC(a, b) {
      b === undefined ? (b = true) : (b = b);
      if (typeof window.OOCustomEvent === 'undefined') {
        try {
          (function () {
            OOo.OOCustomEvent.prototype = window.Event.prototype;
            window.OOCustomEvent = OOo.OOCustomEvent;
          })();
        } catch (e) {}
      }
      if (b) {
        window.dispatchEvent(new J(a));
      }
    }
    function bj(a) {
      if (a) {
        a();
      }
    }
    function bD(a, b, c) {
      var f = a || window.event;
      if (f.preventDefault && f.stopPropagation) {
        f.preventDefault();
        f.stopPropagation();
      } else {
        f.returnValue = false;
      }
      Q(F());
      bi();
      bj(c);
      if (b) {
        b.focus();
      }
    }
    function bE(a, b) {
      var c = b.options;
      if (c.pagesHit && a < c.pagesHit) {
        return false;
      }
      OOo.eraseCookieOrStorage(c, c.tunnelCookie);
      if (OOo.readCookieOrStorage(c, c.percentageCookie) === '1' && !b.queued) {
        b.queued = true;
        OOo.setRepromptAndLaunch(b);
        return true;
      }
    }
    function bF(a) {
      var b = a.options;
      window.setTimeout(
        function () {
          OOo.createCookieOrStorage(
            b,
            b.repromptCookie,
            1,
            b.repromptTime,
            b.cookieDomain
          );
          OOo.eraseCookieOrStorage(b, b.pagesHitCookie);
          b.beforePrompt();
          a.getPrompt();
        }.bind(a),
        b.promptDelay * 1000
      );
    }
    function bG(a, b) {
      var c = (b += 1);
      OOo.createCookieOrStorage(a, a.pagesHitCookie, c, '', a.cookieDomain);
      return c;
    }
    function bH() {
      var a =
        "<div id='oo_invitation_prompt' role='dialog' aria-describedby='oo_invite_message'></div>";
      var b = this.options;
      if (typeof b.inviteMarkup !== 'undefined') {
        a = b.inviteMarkup;
      }
      var c = OOo.$('#oo_invitation_prompt');
      if (c) {
        var f = OOo.$('#oo_container');
        G(F());
        this.queued = false;
        this.showPrompt(f);
        return;
      }
      G(F());
      this.queued = false;
      this.showPrompt(a);
    }
    function bI(c, f) {
      var d = document,
        g = typeof c === 'string' ? d.createElement('div') : c,
        k = d.createElement('div'),
        h,
        o,
        m = this.options,
        q,
        n,
        l,
        p = OOo ? (OOo.fcp ? OOo.fcp : false) : false,
        u = m ? (m.assetType ? m.assetType : 'custom') : '',
        r,
        t = d.activeElement,
        x,
        v,
        s,
        A,
        B = m.pathToAssets ? m.pathToAssets : L(p, u);
      x = m.screenReaderYes ? m.screenReaderYes : 'This will open a new window';
      v = m.screenReaderNo ? m.screenReaderNo : 'Close dialog';
      s = m.screenReaderClose ? m.screenReaderClose : 'Close dialog';
      k.id = 'oo_invitation_overlay';
      g.id = 'oo_container';
      g.style.visibility = 'hidden';
      if (typeof c === 'string') {
        g.innerHTML = c;
        d.body.appendChild(g);
      }
      g.appendChild(k);
      q = OOo.$('#oo_launch_prompt');
      n = OOo.$('#oo_no_thanks');
      l = OOo.$('#oo_close_prompt');
      r = OOo.$('#oo_invitation_company_logo');
      if (!OOo.$('#oo_no_thanks > span.screen_reader')) {
        var z = document.createElement('SPAN');
        z.setAttribute('class', 'screen_reader');
        n.appendChild(z);
      } else {
        var z = OOo.$('#oo_no_thanks > span.screen_reader');
      }
      var C = OOo.$('#oo_launch_prompt > span.screen_reader');
      var w = OOo.$('#oo_close_prompt > div.screen_reader');
      if (m.companyLogo && m.companySlogan) {
        var y = m.companyLogo.split('/');
        var D = y[y.length - 1];
        h = new Image();
        h.onerror = function () {
          this.parentNode.removeChild(this);
        };
        h.src = B + D;
        h.alt = m.companySlogan;
        r.appendChild(h);
      }
      m.companySlogan
        ? OOo.$('#oo_invitation_prompt').setAttribute(
            'aria-label',
            m.companySlogan
          )
        : 'We value your opinion!';
      footerBranding = document.createElement('p');
      footerBranding.innerText = 'Powered by Verint Experience Cloud';
      OOo.$('#ol_invitation_brand_logo').appendChild(footerBranding);
      C.innerHTML = x;
      z.innerHTML = v;
      w.innerHTML = s;
      l.setAttribute('aria-label', s);
      if (m.callBacks) {
        if (typeof m.callBacks.prompt === 'function') {
          m.callBacks.prompt();
        }
        if (typeof m.callBacks.yesClick === 'function') {
          OOo.addEventListener(
            q,
            'click',
            function () {
              m.callBacks.yesClick();
            },
            false
          );
        }
        if (typeof m.callBacks.noClick === 'function') {
          OOo.addEventListener(
            n,
            'click',
            function () {
              m.callBacks.noClick();
            },
            false
          );
        }
        if (typeof m.callBacks.closeClick === 'function') {
          OOo.addEventListener(
            l,
            'click',
            function () {
              m.callBacks.closeClick();
            },
            false
          );
        }
      }
      OOo.addEventListener(q, 'click', f.bind(this), false);
      OOo.addEventListener(
        n,
        'click',
        function (a) {
          var b = a || window.event;
          OOo.hidePrompt(b, t);
        },
        false
      );
      OOo.addEventListener(
        l,
        'click',
        function (a) {
          var b = a || window.event;
          OOo.hidePrompt(b, t);
        },
        false
      );
      OOo.ooTrapFocus(OOo.$('#oo_invitation_prompt'));
      OOo.addEventListener(
        q,
        'keyup',
        function (a) {
          var b = a || window.event;
          if (b.keyCode !== 13) {
            return;
          }
          f.bind(this);
        }.bind(this),
        false
      );
      OOo.addEventListener(
        n,
        'keyup',
        function (a) {
          var b = a || window.event;
          if (b.keyCode !== 13) {
            return;
          }
          OOo.hidePrompt(b, t);
        },
        false
      );
      OOo.addEventListener(
        l,
        'keyup',
        function (a) {
          var b = a || window.event;
          if (b.keyCode !== 13) {
            return;
          }
          OOo.hidePrompt(b, t);
        },
        false
      );
      OOo.addEventListener(
        g,
        'keyup',
        function (a) {
          var b = a || window.event;
          if (b.key === 'Escape' || b.key === 'Esc') {
            OOo.hidePrompt(b, t);
          } else {
            return;
          }
        },
        false
      );
      g.style.visibility = 'visible';
      g.style.display = 'block';
      k.className = 'no_loading';
      t.blur();
      q.focus();
    }
    function bJ() {
      var a =
        "<div id='oo_entry_prompt' role='dialog' aria-modal='true' aria-describedby='oo_entry_message'><div id='oo_entry_company_logo'></div><div id='oo_entry_content'><p id='oo_entry_message'>After your visit, would you be willing to provide some quick feedback?<br /><br />(It will only take a minute)</p><p class='entry_prompt_button'><button tabindex='0' id='oo_launch_entry_prompt'>Yes<span class='screen_reader'>This will open a new window</span></button></p><p class='entry_prompt_button'><button tabindex='0' id='oo_entry_no_thanks'>No Thanks</button></p><p id='ol_entry_brand_logo'></div><button id='oo_entry_close_prompt' tabindex='0' aria-label='Close dialog'><div class='screen_reader'>Close dialog</div><span aria-hidden='true'>&#10006;</span></button></div><!--[if IE 8]><style>/* IE 8 does not support box-shadow */#oo_entry_prompt #oo_entry_content { width: 400px; padding: 40px 49px 20px 49px; border: 1px solid #ccc; }</style><![endif]-->";
      var b = this.options;
      if (b.events.prompt.promptMarkup) {
        a = b.events.prompt.promptMarkup;
      }
      var c = OOo.$('#oo_entry_prompt');
      if (c) {
        var f = OOo.$('#oo_container');
        G(F());
        this.showEntryPrompt(f);
        return;
      }
      G(F());
      this.showEntryPrompt(a);
    }
    function bK(d, g) {
      for (var k = 0; k < d.length; k++) {
        thisCb = g[k];
        (function (c, f) {
          OOo.addEventListener(
            d[k],
            'click',
            function (a) {
              var b = a || window.event;
              f(b);
            },
            false
          );
        })(k, thisCb);
      }
    }
    function bL(c, f) {
      var d = document,
        g = typeof c === 'string' ? d.createElement('div') : c,
        k = d.createElement('div'),
        h = this.options,
        o,
        m,
        q,
        n,
        l,
        p = OOo ? (OOo.fcp ? OOo.fcp : false) : false,
        u = h
          ? h.events.prompt.assetType
            ? h.events.prompt.assetType
            : 'custom'
          : '',
        r,
        t = d.activeElement,
        x,
        v,
        s,
        A,
        B = h.events.prompt.pathToAssets
          ? h.events.prompt.pathToAssets
          : L(p, u);
      x = h.screenReaderYes ? h.screenReaderYes : 'This will open a new window';
      v = h.screenReaderNo ? h.screenReaderNo : 'Close dialog';
      s = h.screenReaderClose ? h.screenReaderClose : 'Close dialog';
      k.id = 'oo_entry_overlay';
      g.id = 'oo_container';
      g.style.visibility = 'hidden';
      if (typeof c === 'string') {
        g.innerHTML = c;
        d.body.appendChild(g);
      }
      g.appendChild(k);
      q = OOo.$('#oo_launch_entry_prompt');
      n = OOo.$('#oo_entry_no_thanks');
      l = OOo.$('#oo_entry_close_prompt');
      r = OOo.$('#oo_entry_company_logo');
      if (!OOo.$('#oo_entry_no_thanks > span.screen_reader')) {
        var z = document.createElement('SPAN');
        z.setAttribute('class', 'screen_reader');
        n.appendChild(z);
      } else {
        var z = OOo.$('#oo_entry_no_thanks > span.screen_reader');
      }
      var C = OOo.$('#oo_launch_entry_prompt > span.screen_reader');
      var w = OOo.$('#oo_entry_close_prompt > div.screen_reader');
      if (h.events.prompt.companyLogo && h.events.prompt.companySlogan) {
        var y = h.events.prompt.companyLogo.split('/');
        var D = y[y.length - 1];
        o = new Image();
        o.onerror = function () {
          this.parentNode.removeChild(this);
        };
        o.src = B + D;
        o.alt = h.events.prompt.companySlogan;
        r.appendChild(o);
      }
      h.events.prompt.companySlogan
        ? OOo.$('#oo_entry_prompt').setAttribute(
            'aria-label',
            h.events.prompt.companySlogan
          )
        : 'We value your opinion!';
      footerBranding = document.createElement('p');
      footerBranding.innerText = 'Powered by Verint Experience Cloud';
      OOo.$('#ol_entry_brand_logo').appendChild(footerBranding);
      C.innerHTML = x;
      z.innerHTML = v;
      w.innerHTML = s;
      l.setAttribute('aria-label', s);
      var H = OOo.hidePrompt.bind(
        this,
        Event,
        t,
        this.options.callBacks && this.options.callBacks.noClick
          ? this.options.callBacks.noClick
          : null
      );
      var I = OOo.hidePrompt.bind(
        this,
        Event,
        t,
        this.options.callBacks && this.options.callBacks.closeClick
          ? this.options.callBacks.closeClick
          : null
      );
      var K = [f.bind(this), H, I];
      var bM = [q, n, l];
      OOo.attachEventToPromptButtons(bM, K);
      OOo.ooTrapFocus(OOo.$('#oo_entry_prompt'));
      OOo.addEventListener(
        q,
        'keyup',
        function (a) {
          var b = a || window.event;
          if (b.keyCode !== 13) {
            return;
          }
          f.bind(this);
        }.bind(this),
        false
      );
      OOo.addEventListener(
        n,
        'keyup',
        function (a) {
          var b = a || window.event;
          if (b.keyCode !== 13) {
            return;
          }
          OOo.hidePrompt(b, t);
        },
        false
      );
      OOo.addEventListener(
        l,
        'keyup',
        function (a) {
          var b = a || window.event;
          if (b.keyCode !== 13) {
            return;
          }
          OOo.hidePrompt(b, t);
        },
        false
      );
      OOo.addEventListener(
        g,
        'keyup',
        function (a) {
          var b = a || window.event;
          if (b.key === 'Escape' || b.key === 'Esc') {
            OOo.hidePrompt(b, t);
          } else {
            return;
          }
        },
        false
      );
      if (
        this.options &&
        this.options.callBacks &&
        this.options.callBacks.prompt
      ) {
        this.options.callBacks.prompt();
      }
      g.style.visibility = 'visible';
      g.style.display = 'block';
      k.className = 'no_loading';
      t.blur();
      q.focus();
    }
    var bN = function (a, b, c, f) {
      var d = 'width=' + c;
      var g = 'height=' + f;
      var k = window.open(a, b, d, g);
      var h = window.setInterval(function () {
        if (k.closed !== false) {
          window.clearInterval(h);
          OOo.oo_feedback.launchOOPopup();
        }
      }, 200);
    };
    var bO = function () {
      return (
        /MSIE\s/.test(navigator.userAgent) &&
        parseFloat(navigator.appVersion.split('MSIE')[1]) < 10
      );
    };
    var bP = function (c, f) {
      if (OOo.Browser.isMobile) {
        if ('ontouchstart' in window) {
          c.ontouchstart = function (a) {
            var b = a || window.event;
            if (b.preventDefault && b.stopPropagation) {
              b.preventDefault();
              b.stopPropagation();
            } else {
              b.returnValue = false;
            }
            this.options.touches++;
          }.bind(this);
          c.ontouchend = function (a) {
            var b = a || window.event;
            if (this.options.touches >= this.options.mobileTouches) {
              this.show(b);
              this.options.touches = 0;
            }
          }.bind(this);
        } else {
          c.onclick = function (a) {
            var b = a || window.event;
            this.show(b);
          }.bind(this);
          X(f, c, this);
        }
      } else {
        c.onclick = function (a) {
          var b = a || window.event;
          this.show(b);
        }.bind(this);
        X(f, c, this);
      }
    };
    function X(b, c, f) {
      if (b) {
        OOo.addEventListener(
          c,
          'keyup',
          function (a) {
            evt = a || window.event;
            if (evt.keyCode !== 13) {
              return;
            }
            f.show(evt);
          }.bind(f),
          false
        );
      } else {
        return;
      }
    }
    function J(a, b) {
      b = b || { bubbles: false, cancelable: false, detail: undefined };
      var c = document.createEvent('CustomEvent');
      c.initCustomEvent(a, b.bubbles, b.cancelable, b.detail);
      return c;
    }
    var bQ = function () {
      if (typeof window.OOCustomEvent === 'undefined') {
        try {
          (function () {
            J.prototype = window.Event.prototype;
            window.OOCustomEvent = J;
          })();
          history.pushState = (function (b) {
            return function c() {
              var a = b.apply(this, arguments);
              window.dispatchEvent(new J('oospachange'));
              return a;
            };
          })(history.pushState);
          history.replaceState = (function (b) {
            return function c() {
              var a = b.apply(this, arguments);
              window.dispatchEvent(new J('oospachange'));
              return a;
            };
          })(history.replaceState);
          window.addEventListener('popstate', function () {
            window.dispatchEvent(new J('oospachange'));
          });
        } catch (e) {}
      }
    };
    function bR(h) {
      var o = h.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled])'
      );
      var m = Array.prototype.slice.call(o);
      var q = o[0];
      var n = o[o.length - 1];
      var l = 9;
      var p = 0;
      OOo.addEventListener(h, 'keydown', function (a) {
        var b = m.indexOf(document.activeElement);
        var c = a || window.event;
        var f = c.key === 'Up' || c.key === 'ArrowUp' || c.keyCode === 38;
        var d = c.key === 'Down' || c.key === 'ArrowDown' || c.keyCode === 40;
        var g = c.key === 'Tab' || c.keyCode === l;
        var k = d || f;
        if (!g && !k) {
          return;
        }
        if (c.shiftKey) {
          if (g) {
            if (document.activeElement === q) {
              n.focus();
              if (c.preventDefault) {
                c.preventDefault();
              } else {
                c.returnValue = false;
              }
            }
          }
        } else {
          if (k) {
            if (f) {
              c.preventDefault();
              p = b > 0 ? b - 1 : m.length - 1;
              o[p].focus();
            } else if (d) {
              c.preventDefault();
              p = b + 1 < m.length ? b + 1 : 0;
              o[p].focus();
            }
          } else if (g) {
            if (document.activeElement === n) {
              q.focus();
              if (c.preventDefault) {
                c.preventDefault();
              } else {
                c.returnValue = false;
              }
            }
          }
        }
      });
    }
    Y(OOo, {
      extend: Y,
      toQueryString: N,
      addEventListener: bn,
      $: bl,
      $$: bm,
      appendOOForm: bs,
      removeEventListener: bo,
      createMetrics: bt,
      truncateMetric: bu,
      createLegacyVars: bv,
      DYNAMIC_FRAME_NAME_IS_BUGGY: bx,
      getFormParams: W,
      referrerRewrite: bw,
      hidePrompt: bD,
      getPrompt: bH,
      showPrompt: bI,
      hideWaypoint: bz,
      getWaypoint: bA,
      showWaypoint: bB,
      getEntryPrompt: bJ,
      showEntryPrompt: bL,
      exitChat: bN,
      checkIfIE9orBelow: bO,
      removeCharacters: E,
      addCustomSpaEvent: bQ,
      getValFromPath: M,
      attachEventToWrapper: bP,
      convertFunctionToObject: R,
      collectKeyNames: S,
      collectValueNames: T,
      checkPrimitiveType: bc,
      prepareCVFunctionValue: bb,
      removeParens: ba,
      assignKeyValue: be,
      evalCustomVars: bq,
      runFnWithArgs: bd,
      OOCustomEvent: J,
      keyPressLaunchCard: X,
      attachEventToPromptButtons: bK,
      removeContainerFromDom: bi,
      invokeCallback: bj,
      callYesCallback: bS,
      convertToEFMParams: bg,
      dispatchOOEvent: bC,
      assessPagesHit: bE,
      incrementPagesHit: bG,
      setRepromptAndLaunch: bF,
      removeMetrics: U,
      assign: Z,
      createFriendlyDateTime: V,
      ooTrapFocus: bR,
      ariaHide: G,
      ariaShow: Q,
      ariaProcessEls: P,
      DOMWatcher: O,
      getDOMElems: F,
      getFirstExistingElement: by,
      checkGateway: bp,
      getPathToAssets: L,
      determineIconFileNameAndLocation: bT,
      focusWaypointLink: bh,
    });
  })();
  (function () {
    function g(a) {
      if (!a) {
        return null;
      }
      switch (typeof a) {
        case 'number':
        case 'boolean':
        case 'function':
          return a;
        case 'string':
          return '"' + a + '"';
        case 'object':
          var b, c, f, d;
          if (a.constructor === Array || typeof a.callee !== 'undefined') {
            b = '[';
            f = a.length;
            for (c = 0; c < f - 1; c += 1) {
              b += g(a[c]) + ',';
            }
            b += g(a[c]) + ']';
          } else {
            b = '{';
            for (d in a) {
              if (a.hasOwnProperty(d)) {
                b += d + ':' + g(a[d]) + ',';
              }
            }
            b = b.replace(/\,$/, '') + '}';
          }
          return b;
        default:
          return null;
      }
    }
    OOo.extend(OOo, { serialize: g });
  })();
  (function () {
    function d(a, b, c) {
      var f;
      if (a.search(b[0]) !== -1) {
        OOo.createCookieOrStorage(this.options, c, 0);
        return false;
      } else if (OOo.readCookieOrStorage(this.storage, c)) {
        f = parseInt(OOo.readCookieOrStorage(this.options, c), 10);
        if (a.search(b[f + 1]) !== -1 && f + 1 !== b.length - 1) {
          OOo.createCookieOrStorage(this.options, c, f + 1);
          return false;
        } else if (a.search(b[f]) !== -1) {
          return false;
        } else if (f + 1 === b.length - 1 && a.search(b.pop()) !== -1) {
          OOo.eraseCookieOrStorage(this.options, c);
          return true;
        } else {
          OOo.eraseCookieOrStorage(this.options, c);
          return false;
        }
      } else {
        return false;
      }
    }
    OOo.extend(OOo, { checkTunnel: d });
  })();
  (function () {
    function h(a, b) {
      if (!b) {
        b = location;
      }
      var c = a.cookieName || 'oo_abandon',
        f = OOo.readCookieOrStorage(this.options, c),
        d = a.startPage,
        g = a.endPage,
        k = a.middle;
      if (!f) {
        if (b.pathname.indexOf(d) !== -1) {
          OOo.createCookieOrStorage(this.options, c);
        }
        return false;
      } else if (b.pathname.indexOf(g) !== -1) {
        OOo.eraseCookieOrStorage(this.options, c);
        return false;
      } else if (b.pathname.search(k) !== -1) {
        return false;
      } else {
        OOo.eraseCookieOrStorage(this.options, c);
        return true;
      }
    }
    OOo.extend(OOo, { checkAbandonment: h });
  })();
  (function () {
    function f(a) {
      var b, c;
      for (b = a.length - 1; b >= 0; b -= 1) {
        if (a[b].read) {
          c = OOo.readCookieOrStorage(this.options, a[b].name);
          if (!!c && c === a[b].value) {
            return true;
          } else if (
            typeof a[b].value === 'undefined' &&
            !!OOo.readCookieOrStorage(this.options, a[b].name)
          ) {
            return true;
          }
        }
      }
      return false;
    }
    function d(a) {
      var b;
      for (b = a.length - 1; b >= 0; b -= 1) {
        if (a[b].set) {
          OOo.createCookieOrStorage(
            this.options,
            a[b].name,
            a[b].value,
            a[b].expiration
          );
        }
      }
    }
    OOo.extend(OOo, { checkThirdPartyCookies: f, setThirdPartyCookies: d });
  })();
  OOo.extend(
    Function.prototype,
    (function () {
      if (typeof Function.prototype.bind !== 'undefined') {
        return;
      }
      var d = Array.prototype.slice;
      function g(a, b) {
        var c = a.length,
          f = b.length;
        while (f) {
          f -= 1;
          a[c + f] = b[f];
        }
        return a;
      }
      function k(a, b) {
        a = d.call(a, 0);
        return g(a, b);
      }
      function h(b) {
        if (arguments.length < 2 && typeof b === 'undefined') {
          return this;
        }
        var c = this,
          f = d.call(arguments, 1);
        return function () {
          var a = k(f, arguments);
          return c.apply(b, a);
        };
      }
      return { bind: h };
    })()
  );
  (function () {
    function k() {
      var a = location;
      var b;
      if (a.host.search(/\.[a-z]+/) !== -1) {
        b = a.host.split('.').reverse();
        if (b.length > 3) {
          return a.host;
        }
        b = '.' + b[1] + '.' + b[0];
      } else {
        b = a.host;
      }
      return b;
    }
    function h(a, b, c, f) {
      var d = '',
        g = '';
      if (c) {
        d = new Date();
        d.setTime(d.getTime() + c * 1000);
        g = '; expires=' + d.toGMTString();
      }
      a = OOo.removeCharacters(a);
      b = OOo.removeCharacters(b);
      g = OOo.removeCharacters(g);
      if (f) {
        f = OOo.removeCharacters(f);
        document.cookie = a + '=' + b + g + '; path=/; domain=' + f + ';';
      } else if (location.host !== k()) {
        document.cookie = a + '=' + b + g + '; path=/; domain=' + k() + ';';
      } else {
        document.cookie = a + '=' + b + g + '; path=/;';
      }
    }
    function o(a) {
      var b = a + '=',
        c = document.cookie.split(';'),
        f,
        d;
      for (d = 0; d < c.length; d += 1) {
        f = c[d];
        while (f.charAt(0) === ' ') {
          f = f.substring(1, f.length);
        }
        if (f.indexOf(b) === 0) {
          return f.substring(b.length, f.length);
        }
      }
      return null;
    }
    function m(a) {
      h(a, '', -1);
    }
    function q(a, b, c, f, d) {
      var b = b || '',
        c = c || '',
        f = f || 0;
      a.useBrowserStorage
        ? OOo.createBrowserStorageItem(b, c, f)
        : OOo.createCookie(b, c, f, d);
    }
    function n(a, b) {
      return a.useBrowserStorage
        ? OOo.readBrowserStorageItem(b)
        : OOo.readCookie(b);
    }
    function l(a, b) {
      a.useBrowserStorage ? OOo.eraseLocalStorageItem(b) : OOo.eraseCookie(b);
    }
    function p() {
      var a = 'oo_test';
      try {
        localStorage.setItem(a, a);
        localStorage.removeItem(a);
        return true;
      } catch (e) {
        return false;
      }
    }
    function u() {
      var a = 'oo_test';
      try {
        sessionStorage.setItem(a, a);
        sessionStorage.removeItem(a);
        return true;
      } catch (e) {
        return false;
      }
    }
    function r(a, b, c) {
      var f = new Date().getTime(),
        c = c || 0;
      if (u && (typeof c === 'undefined' || c === 0)) {
        sessionStorage.setItem(a, JSON.stringify({ oo_val: b, dateSet: f }));
        return;
      } else {
        if (p) {
          localStorage.setItem(
            a,
            JSON.stringify({ oo_val: b, dateSet: f, dateExpire: c * 1000 })
          );
        }
      }
    }
    function t(a) {
      var b = JSON.parse(sessionStorage.getItem(a)),
        c = JSON.parse(localStorage.getItem(a));
      if (b && b.oo_val) {
        return b.oo_val;
      } else if (c && new Date().getTime() >= c.dateSet + c.dateExpire) {
        localStorage.removeItem(a);
        return null;
      } else {
        if (c && c.oo_val) {
          return c.oo_val;
        } else {
          return null;
        }
      }
    }
    function x(a) {
      if (p) {
        if (localStorage.getItem(a)) {
          localStorage.removeItem(a);
        }
      }
    }
    OOo.extend(OOo, {
      getCookieDomain: k,
      createCookie: h,
      readCookie: o,
      eraseCookie: m,
      createBrowserStorageItem: r,
      readBrowserStorageItem: t,
      eraseLocalStorageItem: x,
      createCookieOrStorage: q,
      readCookieOrStorage: n,
      eraseCookieOrStorage: l,
    });
  })();
  OOo.Ocode = function (b) {
    var c = OOo.Browser,
      f,
      d;
    var g = this;
    if (b.disableMobile && c.isMobile) {
      return;
    }
    if (b.disableDesktop && c.isDesktop) {
      return;
    }
    if (b.disableNoniOS && c.isMobileNonIOS) {
      return;
    }
    OOo.instanceCount += 1;
    this.options = { tealeafCookieName: 'TLTSID' };
    OOo.extend(this.options, b);
    f = this.options;
    if (f.spa || OOo.spa) {
      OOo.addCustomSpaEvent();
    }
    f.metrics = OOo.createMetrics();
    this.frameName = encodeURIComponent(
      f.onPageCard ? 'OnlineOpinion' + OOo.instanceCount : 'OnlineOpinion'
    );
    if (f.cookie && OOo.Ocode.matchUrl.call(this, f.cookie, location)) {
      if ((f.spa || OOo.spa) && f.events) {
        OOo.addEventListener(window, 'oospachange', function k() {
          if (f.cookie && OOo.Ocode.matchUrl.call(g, f.cookie, location)) {
            return;
          }
          g.setUpAndDisableEventsElements(f, g);
        });
      }
      return;
    }
    if (
      f.thirdPartyCookies &&
      OOo.checkThirdPartyCookies(f.thirdPartyCookies)
    ) {
      return;
    }
    if (f.abandonment && !OOo.checkAbandonment(f.abandonment)) {
      return;
    }
    if (
      f.tunnel &&
      !OOo.checkTunnel(location.pathname, f.tunnel.path, f.tunnel.cookieName)
    ) {
      return;
    }
    if (f.events && f.events.onSingleClick) {
      this.singProbability = Math.random() < 1 - f.events.onSingleClick / 100;
    } else if (
      f.events &&
      !f.events.onSingleClick &&
      f.events.onSingleClick < 1
    ) {
      this.singProbability = true;
    }
    f.tealeafId =
      OOo.readCookieOrStorage(f, f.tealeafCookieName) ||
      OOo.readCookieOrStorage(f, f.sessionCookieName);
    if (f.events) {
      if ((f.spa || OOo.spa) && f.events.onEntry) {
        OOo.addEventListener(window, 'oospachange', function k() {
          if (f.cookie && OOo.Ocode.matchUrl.call(g, f.cookie, location)) {
            return;
          }
          g.setUpAndDisableEventsElements(f, g);
        });
      }
      this.setUpAndDisableEventsElements(f, g);
      if ((f.spa || OOo.spa) && f.events.onExit) {
        OOo.addEventListener(window, 'oospachange', function h(a) {
          OOo.removeEventListener(window, 'oospachange', h);
          if (OOo.Browser.Opera) {
            OOo.removeEventListener(window, 'beforeunload', a.boundShow);
          } else if (OOo.Browser.iOs) {
            OOo.removeEventListener(window, 'pagehide', a.boundShow);
          } else {
            OOo.removeEventListener(window, 'beforeunload', a.boundShow);
          }
          OOo.removeEventListener(document.body, 'mousedown', a.boundDisable);
          OOo.removeEventListener(document.body, 'keydown', a.boundDisable);
        });
      }
    }
    if (f.floating) {
      this.floating();
    } else if (f.bar) {
      this.bar();
    } else if (f.tab) {
      this.tab();
    }
  };
  function bU(a, b) {
    b.setupEvents();
    if (
      a.events.disableLinks ||
      a.events.disableFormElements ||
      a.events.disableSelectors
    ) {
      b.setupDisableElements();
    }
  }
  function bS(a) {
    if (a.callBacks && a.callBacks.yesClick) {
      a.callBacks.yesClick();
    }
  }
  function bT(a, b, c, f) {
    var d,
      g,
      k = a ? a.search(/\//) > -1 : false,
      h = a ? a.search(/^.*\.(jpg|gif|png|svg|jpeg|tiff)$/gi) > -1 : false;
    if (a && k && h) {
      return a;
    } else if (a && !k && h) {
      d = a;
      return OOo.getPathToAssets(b, 'custom') + d;
    } else if (!a || (a && !k && !h)) {
      g = c ? (c == 'custom' ? 'custom' : f) : f;
      d = 'oo_icon_' + g + '.gif';
      return OOo.getPathToAssets(b, c) + d;
    } else {
      return;
    }
  }
  OOo.Ocode.prototype = {
    show: function (d, g) {
      var k = d || window.event;
      if (g !== 'exit' && g !== 'entry' && g !== 'onSingleClick') {
        if (k.preventDefault && k.stopPropagation) {
          k.preventDefault();
          k.stopPropagation();
        } else {
          k.returnValue = false;
        }
      }
      if (this.onPageCardVisible) {
        return;
      }
      var h = this.options,
        o;
      if (h.events && h.events.prompt) {
        if (h.cookie) OOo.eraseCookieOrStorage(h, h.cookie.name || 'oo_r');
        OOo.callYesCallback(h);
        OOo.hidePrompt(k);
      }
      if (this.interruptShow) {
        return;
      }
      if (
        !this.floatingLogo &&
        h.cookie &&
        OOo.Ocode.matchUrl.call(this, h.cookie)
      ) {
        return;
      }
      if (!h.floating && h.events && this.singProbability) {
        return;
      }
      if (h.events && h.events.onSingleClick) {
        this.singProbability = true;
      }
      if (h.cookie) {
        OOo.Ocode.tagUrl.call(this, h.cookie);
      }
      if (h.thirdPartyCookies) {
        if (OOo.checkThirdPartyCookies(h.thirdPartyCookies)) {
          return;
        }
        OOo.setThirdPartyCookies(h.thirdPartyCookies);
      }
      if (this.floatingLogo) {
        this.floatingLogo.children[0].blur();
      }
      if (this.floatingLogo && h.disappearOnClick) {
        this.floatingLogo.style.display = 'none';
      }
      if (typeof window.ClickTale === 'function') {
        if (!h.clickTalePID) {
          h.clickTalePID = window.ClickTaleGetPID() || null;
        }
        h.clickTaleUID = window.ClickTaleGetUID() || null;
        h.clickTaleSID = window.ClickTaleGetSID() || null;
        var m = function (a) {
          if (a.origin === 'https://secure.opinionlab.com') {
            if (typeof window.ClickTaleEvent === 'function' && a.data !== '') {
              var b = JSON.parse(a.data),
                c = window.ClickTaleEvent;
              for (var f in b) {
                c(f + ':' + b[f]);
              }
            }
          }
        };
        OOo.addEventListener(window, 'message', m, false);
      }
      OOo.dispatchOOEvent('OL_CC_Launch');
      if (h.onPageCard && !OOo.Browser.isMobile) {
        this.setupOnPageCC();
      } else {
        this.launchOOPopup();
      }
      if (typeof h.onClickCallback === 'function') {
        h.onClickCallback();
      }
    },
  };
  OOo.extend(OOo.Ocode, {
    tagUrl: function (a, b) {
      if (!b) {
        b = location;
      }
      var c = a.name || 'oo_r',
        f = a.type === 'page' ? b.href : b.hostname,
        d = OOo.readCookieOrStorage(this.options, c) || '';
      if (OOo.Ocode.matchUrl.call(this, a, b)) {
        return;
      }
      OOo.createCookieOrStorage(this.options, c, d + f, a.expiration, a.domain);
    },
    matchUrl: function (a, b) {
      if (!b) {
        b = location;
      }
      var c = OOo.readCookieOrStorage(this.options, a.name || 'oo_r'),
        f;
      if (!c) {
        return false;
      }
      f = a.type === 'page' ? b.href : b.hostname;
      return c.search(f) !== -1;
    },
  });
  (function () {
    var o = 0;
    function m() {
      var a = this.options,
        b = a.newWindowSize || [545, 325],
        c = [
          parseInt((a.metrics.height - b[1]) / 2, 10),
          parseInt((a.metrics.width - b[0]) / 2, 10),
        ],
        f,
        d,
        g =
          'resizable=yes,location=no,status=no,scrollbars=1,width=' +
          b[0] +
          ',height=' +
          b[1] +
          ',top=' +
          c[0] +
          ',left=' +
          c[1],
        k = 'OnlineOpinion',
        h;
      if (a.newWindow) {
        k = k + o++;
      }
      a.metrics.time2 = new Date().getTime();
      a.metrics.type = 'Popup';
      a.metrics.currentURL = location.href;
      if (OOo.Browser.isChromeIOS) {
        k = '_0';
      }
      if (OOo.Browser.isSafariDesktop) {
        a.constructCommentCardUrl = true;
      }
      if (a.constructCommentCardUrl) {
        h = OOo.appendOOForm(a, k);
        d = window.open(h, k, g);
      } else {
        f = OOo.appendOOForm(a, k);
        var h =
          'https://secure.opinionlab.com/ccc01/comment_card_d.asp?' +
          f.children[0].value;
        if (a.commentCardUrl) {
          h = a.commentCardUrl + '?' + f.children[0].value;
        }
        if (
          (OOo.Browser.isMobile && OOo.Browser.ua.search('Android') !== -1) ||
          !OOo.Browser.isMobile
        ) {
          d = window.open(h, k, g);
          f.submit();
        } else {
          d = window.open('', k, g);
          if (d.location.href === 'about:blank') {
            d.location.href = h;
          } else {
            d.close();
            d = window.open(h, k, g);
          }
          if (d && !OOo.Browser.isChromeIOS) {
            f.submit();
          }
        }
      }
    }
    OOo.extend(OOo.Ocode.prototype, { launchOOPopup: m });
  })();
  (function () {
    function q() {
      var c = this.options.events,
        f = [false, false],
        d = ['onExit', 'onEntry'],
        g = 'beforeunload',
        k,
        h,
        o,
        m;
      if (OOo.Browser.Opera) {
        g = 'unload';
      }
      if (OOo.Browser.iOs) {
        g = 'pagehide';
      }
      if (c.prompt) {
        OOo.extend(this.options, {
          promptMarkup: c.prompt.promptMarkup,
          neverShowAgainButton: false,
          pathToAssets: c.prompt.pathToAssets,
        });
      }
      for (h = d.length - 1; h >= 0; h -= 1) {
        k = d[h];
        if (c[k] instanceof Array) {
          o = c[k];
          m = o.length;
          while (m && !f[h]) {
            m -= 1;
            if (
              window.location.href.search(o[m].url) !== -1 &&
              Math.random() >= 1 - o[m].p / 100
            ) {
              f[h] = true;
              this.qualified = true;
            } else {
              this.qualified = false;
              n(this);
            }
          }
        } else if (c[k]) {
          if (Math.random() >= 1 - c[k] / 100) {
            f[h] = true;
            this.qualified = true;
          } else {
            this.qualified = false;
            n(this);
          }
        }
      }
      if (f[0]) {
        this.showOnUnload = function (a) {
          var b = a || window.event;
          this.show(b, 'exit');
        }.bind(this);
        this.boundShow = this.showOnUnload.bind(this);
        OOo.addEventListener(window, g, this.boundShow, false);
      }
      if (f[1]) {
        if (c.delayEntry) {
          n(this);
        } else {
          if (c.prompt) {
            this.getEntryPrompt();
          } else {
            (function (a) {
              var b = a || window.event;
              this.show(b, 'entry');
            }.bind(this)());
          }
        }
      }
    }
    function n(a) {
      var b = a.options.events;
      if (
        b.terminateOnSpaChange &&
        (typeof b.terminateOnSpaChange === 'object' ||
          typeof b.terminateOnSpaChange === 'string')
      ) {
        var c = a.handleTimer('terminate', a);
      }
      if (c) {
        return;
      }
      if (b.enableOnSpaChange || b.enableOnSpaChange === false) {
        if (typeof b.enableOnSpaChange !== 'boolean') {
          a.handleTimer('enable', a);
        } else if (b.enableOnSpaChange === false) {
          if (typeof b.cancelOnSpaChange === 'undefined') {
            a.options.events.cancelOnSpaChange = true;
          }
          a.handleTimer('cancel', a);
        } else if (b.enableOnSpaChange === true) {
          a.handleTimer('enable', a);
        }
      } else if (b.cancelOnSpaChange || b.cancelOnSpaChange === false) {
        if (b.cancelOnSpaChange !== false) {
          a.handleTimer('cancel', a);
        }
      } else {
        a.options.events.cancelOnSpaChange = true;
        a.handleTimer('cancel', a);
      }
    }
    function l(d) {
      this.delayRunning = true;
      d.entryDelayTimer = window.setTimeout(
        function (c) {
          if (
            d.options.cookie &&
            OOo.Ocode.matchUrl.call(d, d.options.cookie, location)
          ) {
            return;
          }
          var f = c || window.event;
          if (this.options.events.prompt) {
            this.getEntryPrompt();
          } else {
            (function (a) {
              var b = a || window.event;
              this.show(b, 'entry');
            }.bind(this)());
          }
          this.delayRunning = false;
        }.bind(d),
        d.options.events.delayEntry * 1000
      );
    }
    function p(a, b) {
      var c = b.options.events;
      var f;
      switch (a) {
        case 'enable':
          f = c.enableOnSpaChange;
          state = false;
          break;
        case 'cancel':
          f = c.cancelOnSpaChange;
          state = true;
          break;
        case 'terminate':
          f = c.terminateOnSpaChange
            ? c.terminateOnSpaChange
            : c.cancelOnSpaChange;
          state = true;
          break;
        default:
          state = false;
      }
      if (Array.isArray(f)) {
        for (matcher in f) {
          if (window.location.href.search(f[matcher]) >= 0) {
            return {
              keepTimer: !state,
              terminate: a === 'terminate' ? true : false,
            };
          }
        }
      } else if (typeof f === 'boolean') {
        return {
          keepTimer: !state,
          terminate: a === 'terminate' ? true : false,
        };
      } else if (window.location.href.search(f) >= 0) {
        return {
          keepTimer: !state,
          terminate: a === 'terminate' ? true : false,
        };
      }
      return { keepTimer: state, terminate: a === 'terminate' ? true : false };
    }
    function u(a, b) {
      if (
        b.options.cookie &&
        OOo.Ocode.matchUrl.call(b, b.options.cookie, location)
      ) {
        return;
      }
      var c = p(a, b);
      if (!c.keepTimer) {
        if (b.delayRunning && c.terminate) {
          b.qualified = false;
        }
        clearTimeout(b.entryDelayTimer);
        b.delayRunning = false;
        if (b.qualified === false && c.terminate) {
          return 'terminate';
        } else if (b.qualified) {
          b.entryTimeout(b);
        }
      } else if (b.qualified && !b.delayRunning && !c.terminate) {
        b.entryTimeout(b);
      }
    }
    function r(a) {
      var b = a || window.event,
        c = a.target || a.srcElement,
        f = this.options.events,
        d = c.parentNode,
        g = 5,
        k = 0;
      while (d && (c.nodeName !== 'A' || c.nodeName !== 'INPUT') && k !== g) {
        if (d.nodeName === 'A') {
          c = d;
        }
        d = d.parentNode;
        k += 1;
      }
      if (
        f.disableFormElements &&
        (c.tagName === 'INPUT' || c.tagName === 'BUTTON') &&
        (c.type === 'submit' ||
          c.type === 'image' ||
          c.type === 'reset' ||
          c.type === 'button')
      ) {
        this.interruptShow = true;
      }
      if (
        f.disableLinks &&
        (c.nodeName === 'A' || c.nodeName === 'AREA') &&
        c.href.substr(0, 4) === 'http' &&
        c.href.search(f.disableLinks) !== -1
      ) {
        this.interruptShow = true;
      }
    }
    function t(a) {
      this.interruptShow = true;
    }
    function x(a) {
      this.getDOMElemsBySelector(this.makeArrOfSelectors(this));
    }
    function v(a) {
      var b = a.options.events.disableSelectors;
      return b;
    }
    function s(a) {
      if (typeof a === 'object' && a.length !== 0) {
        var b;
        for (i = 0; i < a.length; i++) {
          b = document.querySelectorAll(a[i]);
          this.addEventListenerToDOMEls(b);
        }
      }
    }
    function A(a) {
      if (!!a) {
        for (j = 0; j < a.length; j++) {
          OOo.addEventListener(a[j], 'mousedown', t.bind(this));
          OOo.addEventListener(a[j], 'keydown', t.bind(this));
        }
      }
    }
    function B() {
      this.boundDisable = r.bind(this);
      OOo.addEventListener(document.body, 'mousedown', this.boundDisable);
      OOo.addEventListener(document.body, 'keydown', this.boundDisable);
      if (this.options.events.disableSelectors) {
        x.call(this);
      }
      if (!this.options.events.disableFormElements) {
        return;
      }
      var a = document.getElementsByTagName('form'),
        b;
      for (b = a.length - 1; b >= 0; b -= 1) {
        OOo.addEventListener(a[b], 'submit', t.bind(this));
      }
    }
    OOo.extend(OOo.Ocode.prototype, {
      setupEvents: q,
      setupDisableElements: B,
      setUpAndDisableEventsElements: bU,
      disableSelectors: x,
      makeArrOfSelectors: v,
      getDOMElemsBySelector: s,
      addEventListenerToDOMEls: A,
      disableSurvey: t,
      checkForURLMatch: p,
      handleTimer: u,
      entryTimeout: l,
      getEntryPrompt: function () {
        OOo.getEntryPrompt.call(this);
      },
      showEntryPrompt: function (a) {
        if (this.options.cookie) {
          OOo.Ocode.tagUrl.call(this, this.options.cookie);
        }
        OOo.showEntryPrompt.call(this, a, this.show);
      },
    });
  })();
  OOo.extend(OOo.Ocode.prototype, {
    floating: function () {
      var f = document,
        d = (this.floatingLogo = document.createElement('div')),
        g = f.createElement('div'),
        k = f.createElement('img'),
        h = f.createElement('span'),
        o = f.createElement('div'),
        m = f.createElement('button'),
        q = f.createElement('span'),
        n = this.options,
        l = n.floating,
        p = OOo.$('#' + n.contentId),
        u = '10px',
        r = l.id,
        t = f.createElement('span'),
        x,
        v,
        s,
        A,
        B,
        z = this.options.mobileTouches || 2,
        C = OOo.getFirstExistingElement(this.options.altParents, f.body);
      fcp = OOo ? (OOo.fcp ? OOo.fcp : false) : false;
      (l.iconColor = l ? (l.iconColor ? l.iconColor : 'black') : ''),
        (l.assetType = l ? (l.assetType ? l.assetType : 'static') : ''),
        (floatIconPath = OOo.determineIconFileNameAndLocation(
          l.iconPath,
          fcp,
          l.assetType,
          l.iconColor
        ));
      this.options.mobileTouches = this.options.mobileTouches || 2;
      this.options.touches = 0;
      l.icon = l.icon === undefined ? true : l.icon;
      k.onerror = function () {
        this.parentNode.removeChild(this);
        h.className += ' no_image';
      };
      function w(a) {
        return a.offsetLeft + a.offsetWidth;
      }
      function y(a) {
        v.style.left = w(p) + 'px';
      }
      t.innerHTML = l.screenReader
        ? l.screenReader
        : 'This will open a new window';
      t.className = 'screen_reader';
      if (r) {
        d.id = r;
      }
      d.className = 'oo_feedback_float';
      o.className = 'oo_transparent';
      g.className = 'olUp';
      m.className = 'olOver';
      k.setAttribute('src', floatIconPath);
      k.className = 'float_icon';
      h.className = 'float_text';
      d.tabIndex = 0;
      h.innerHTML = l.caption || 'Feedback';
      if (l.icon) {
        g.appendChild(k);
      } else {
        h.className += ' no_image';
      }
      g.appendChild(h);
      d.appendChild(t);
      d.appendChild(g);
      q.innerHTML = l.hoverCaption || 'Click here to<br>rate this page';
      m.appendChild(q);
      d.appendChild(m);
      d.appendChild(o);
      if (l.position && l.position.search(/Content/) && p) {
        v = this.spacer = f.createElement('div');
        s = OOo.Browser.WebKit ? f.body : f.documentElement;
        v.id = 'oo_feedback_fl_spacer';
        v.style.left = w(p) + 'px';
        C.appendChild(v);
        switch (l.position) {
          case 'rightOfContent':
            A = function (a) {
              d.style.left = w(p) - s.scrollLeft + 'px';
            };
            break;
          case 'fixedPreserveContent':
            A = function (a) {
              var b = OOo.Browser.IE ? f.body.clientWidth : window.innerWidth,
                c = s.scrollLeft;
              if (b <= w(p) + d.offsetWidth + parseInt(u, 10)) {
                d.style.left = w(p) - c + 'px';
              } else {
                d.style.left = '';
                d.style.right = u;
              }
            };
            break;
          case 'fixedContentMax':
            A = function (a) {
              var b = OOo.Browser.IE ? f.body.clientWidth : window.innerWidth;
              if (b <= w(p) + d.offsetWidth + parseInt(u, 10)) {
                d.style.left = '';
                d.style.right = u;
              } else {
                d.style.left = w(p) - s.scrollLeft + 'px';
                d.style.right = '';
              }
            };
            break;
        }
        window.setTimeout(A, 0);
        OOo.addEventListener(window, 'scroll', A, false);
        OOo.addEventListener(window, 'resize', A, false);
        OOo.addEventListener(window, 'resize', y, false);
      } else {
        d.style.right = u;
      }
      if (!this.options.disableShow) {
        OOo.attachEventToWrapper.call(this, d, true);
      }
      C.appendChild(d);
    },
  });
  OOo.extend(OOo.Ocode.prototype, {
    bar: function () {
      var a = document,
        b = (this.floatingLogo = a.createElement('button')),
        c = a.createElement('span'),
        f = this.options,
        d = f ? (f.bar ? f.bar : undefined) : undefined,
        g = a.createElement('img'),
        k = a.createElement('span'),
        h = OOo.getFirstExistingElement(this.options.altParents, a.body);
      fcp = OOo ? (OOo.fcp ? OOo.fcp : false) : false;
      (d.assetType = d ? (d.assetType ? d.assetType : 'static') : ''),
        (d.iconColor = d ? (d.iconColor ? d.iconColor : 'white') : ''),
        (barIconPath = OOo.determineIconFileNameAndLocation(
          d.iconPath,
          fcp,
          d.assetType,
          d.iconColor
        ));
      f.mobileTouches = f.mobileTouches || 2;
      f.touches = 0;
      d.icon = d.icon === undefined ? true : d.icon;
      c.innerHTML = d.screenReader
        ? d.screenReader
        : 'This will open a new window';
      c.className = 'screen_reader';
      g.className = 'bar_icon';
      g.onerror = function () {
        this.parentNode.removeChild(this);
      };
      g.setAttribute('src', barIconPath);
      k.className = 'bar_text';
      this.reflowBar = OOo.K;
      b.id = 'oo_bar';
      b.setAttribute('tabindex', '0');
      b.setAttribute('role', 'link');
      k.innerHTML = d.caption || 'Feedback';
      b.appendChild(k);
      b.appendChild(c);
      if (d.icon) {
        b.appendChild(g);
      }
      if (!f.disableShow) {
        OOo.attachEventToWrapper.call(this, b);
      }
      h.appendChild(b);
    },
  });
  OOo.extend(OOo.Ocode.prototype, {
    tab: function () {
      var a = document,
        b = (this.floatingLogo = a.createElement('button')),
        c = a.createElement('span'),
        f = this.options,
        d = f.tab,
        g = 'Feedback',
        k = d.tabType,
        h = 'right',
        o = false,
        m = OOo.readCookieOrStorage(f, 'oo_tab_extend'),
        q = 2592000,
        n = OOo.getFirstExistingElement(this.options.altParents, a.body);
      (tabImg = document.createElement('img')),
        (fcp = OOo ? (OOo.fcp ? OOo.fcp : false) : false);
      (d.assetType = d ? (d.assetType ? d.assetType : 'static') : ''),
        (d.iconColor = d ? (d.iconColor ? d.iconColor : 'white') : ''),
        (tabIconPath = OOo.determineIconFileNameAndLocation(
          d.iconPath,
          fcp,
          d.assetType,
          d.iconColor
        ));
      this.options.mobileTouches = this.options.mobileTouches || 2;
      this.options.touches = 0;
      if (f.icon == undefined) {
        f.icon = true;
      }
      switch (k) {
        case 1:
          b = this.floatingLogo = a.createElement('button');
          c = a.createElement('span');
          b.setAttribute('tabindex', '0');
          b.setAttribute('role', 'link');
          b.id = 'oo_tab_' + k;
          if (d.position) {
            h = d.position;
          }
          if (d.extendEveryPage) {
            o = d.extendEveryPage;
          }
          if (d.extendExpiration) {
            q = d.extendExpiration;
          }
          b.className = tabClass = 'oo_tab_' + h + '_' + k;
          c.className = 'screen_reader';
          tabImg.className = 'tab_icon';
          if (typeof d.tabIndex === 'number') {
            b.tabIndex = d.tabIndex;
          } else {
            b.tabIndex = 0;
          }
          if (d.verbiage) {
            g = d.verbiage;
          }
          b.innerHTML = g;
          c.innerHTML = d.screenReader
            ? d.screenReader
            : 'This will open a new window';
          b.appendChild(c);
          if (o) {
            setTimeout(function () {
              b.className += ' small';
            }, 2500);
          } else {
            if (m === 'prevent') {
              b.className += ' small';
            } else {
              var l;
              if (f.cookie && f.cookie.domain) {
                l = f.cookie.domain;
              } else {
                l = OOo.getCookieDomain();
              }
              OOo.createCookieOrStorage(f, 'oo_tab_extend', 'prevent', q, l);
              setTimeout(function () {
                b.className += ' small';
              }, 2500);
            }
          }
          break;
        default:
          b = this.floatingLogo = a.createElement('button');
          b.id = 'oo_tab';
          b.className = 'oo_tab_' + (d.position || h);
          b.setAttribute('tabindex', '0');
          b.setAttribute('role', 'link');
          if (!document.addEventListener || OOo.checkIfIE9orBelow()) {
            b.className += ' oo_legacy';
          }
          if (d.wcagBasePath) {
            b.className += ' wcag';
          }
          if (d.verbiage) {
            g = d.verbiage;
          }
          var p = document.createElement('textarea');
          p.innerHTML = g;
          g = p.value;
          var u = document.createTextNode(g);
          b.appendChild(u);
          if (c) {
            c.className = 'screen_reader';
            c.innerHTML = d.screenReader
              ? d.screenReader
              : 'This will open a new window';
            b.appendChild(c);
          }
      }
      d.icon = d.icon === undefined ? true : d.icon;
      if (d.icon) {
        tabImg.setAttribute('src', '/assets/vendors/verint/v5/' + tabIconPath);
        tabImg.setAttribute('alt', d.logoAltText || 'OpinionLab Logo');
        b.appendChild(tabImg);
        tabImg.onerror = function () {
          this.parentNode.removeChild(this);
        };
      }
      if (!this.options.disableShow) {
        OOo.attachEventToWrapper.call(this, b);
      }
      n.appendChild(b);
    },
  });
  OOo.extend(OOo.Ocode.prototype, {
    setupOnPageCC: function () {
      var d = document,
        g = OOo.Cache.overlay || d.createElement('div'),
        k = (this.wrapper = d.createElement('div')),
        h = d.createElement('a'),
        o = OOo.Cache.loadingImg || d.createElement('img');
      OOo.Cache.overlay = g;
      OOo.Cache.loadingImg = o;
      g.id = 'oo_overlay';
      o.id = 'loading_icon';
      var m = OOo ? (OOo.fcp ? OOo.fcp : false) : false,
        q = d.createElement('div'),
        n = d.createElement('span'),
        l = encodeURIComponent(this.frameName),
        p = d.createElement(
          OOo.DYNAMIC_FRAME_NAME_IS_BUGGY
            ? '<iframe name="' + l + '">'
            : 'iframe'
        ),
        u = d.createDocumentFragment(),
        r = this.options,
        t = r.onPageCard,
        x = r.onPageCardHeight,
        v,
        s = 'https://secure.opinionlab.com/ccc01/comment_card_json_4_0_b.asp',
        A,
        B,
        z = false,
        C = this,
        w,
        y,
        D,
        H = d.createElement('span');
      p.setAttribute(
        'sandbox',
        'allow-forms allow-scripts allow-same-origin allow-popups-to-escape-sandbox allow-popups'
      );
      var t = r.onPageCard;
      if (r.events) {
        if (r.events.prompt) {
          v = r.events;
        } else {
          v = undefined;
        }
      } else if (r.bar) {
        v = r.bar;
      } else if (r.tab) {
        v = r.tab;
      } else if (r.floating) {
        v = r.floating;
      } else {
        v = undefined;
      }
      var I = v ? (v.assetType ? v.assetType : 'static') : '';
      o.setAttribute(
        'src',
        OOo.getPathToAssets(m, 'static') + 'oo_loading.gif'
      );
      function K(a) {
        OOo.ariaShow(OOo.getDOMElems());
        if (a && a.preventDefault) {
          a.preventDefault();
        }
        document.body.focus();
        p.tabIndex = -1;
        p.title = 'empty';
        p['aria-hidden'] = 'true';
        g.style.display = 'none';
        g.className = '';
        d.body.removeChild(k);
        if (window.postMessage) {
          OOo.removeEventListener(window, 'message', w);
        } else {
          window.clearInterval(B);
        }
        z = false;
        C.onPageCardVisible = false;
        return false;
      }
      w = OOo.Ocode.postMessageHandler(function (a) {
        var b = typeof a === 'object' ? parseInt(a[1], 10) : parseInt(a, 10),
          c,
          f;
        if (b > 0) {
          if (z && (typeof a !== 'object' || a[0] !== 'frameHeightOverride')) {
            return;
          }
          z = true;
          c =
            window.innerHeight ||
            d.documentElement.clientHeight ||
            d.body.clientHeight;
          f = b;
          D = k.offsetTop;
          if (f + D > c) {
            f = c - 40 - D;
          }
          f = x ? x : f;
          p.style.width = '555px';
          q.style.width = '555px';
          p.style.height = f + 'px';
          k.style.top = (c - f - 40) / 2 + 'px';
          k.style.visibility = 'visible';
          if (n.clientHeight < 20) {
            n.style.height = k.offsetHeight + 'px';
          }
          g.className = 'no_loading';
          C.onPageCardVisible = true;
          typeof document.forms[0] !== 'undefined' && d.body.removeChild(A);
        } else if (a === 'submitted') {
          K();
        }
        if (OOo.Browser.IE && d.compatMode === 'BackCompat') {
          window.scrollTo(0, 0);
        }
      }, C.options.commentCardUrl);
      r.metrics.type = 'OnPage';
      OOo.Cache.overlay = g;
      g.id = 'oo_overlay';
      g.style.display = 'block';
      g.className = '';
      q.className = 'iwrapper';
      k.className = 'oo_cc_wrapper';
      o.className = 'loading_icon';
      k.setAttribute('role', 'alert');
      k.setAttribute('aria-describedby', 'comment_card_description');
      H.className = 'screen_reader';
      H.id = 'comment_card_description';
      H.innerHTML =
        'Please leave your feedback in the comment card you just activated';
      k.appendChild(H);
      h.className = 'oo_cc_close';
      h.innerHTML =
        '<span class="screen_reader">Close dialog</span><span aria-hidden="true">&#10006;</span>';
      h.title = r.closeTitle ? r.closeTitle : 'Close dialog';
      if (!d.addEventListener) {
        q.style.outline = '1px solid #cdcdcd';
      }
      k.style.visibility = 'hidden';
      h.tabIndex = 0;
      OOo.addEventListener(
        h,
        'keyup',
        function (a) {
          var b = a || window.event;
          if (b.keyCode !== 13) {
            return;
          }
          K();
        },
        false
      );
      if (OOo.Browser.IE) {
        p.frameBorder = '0';
        if (!window.XMLHttpRequest || d.compatMode === 'BackCompat') {
          y = Math.max(d.documentElement.clientWidth, d.body.offsetWidth);
          g.style.position = 'absolute';
          g.style.width =
            d.compatMode === 'BackCompat' ? y - 21 + 'px' : y + 'px';
          g.style.height =
            Math.max(d.documentElement.clientHeight, d.body.offsetHeight) +
            'px';
          k.style.position = 'absolute';
          OOo.addEventListener(window, 'scroll', function () {
            g.style.top =
              d.body.scrollTop +
              document.body.clientHeight -
              g.clientHeight +
              'px';
            k.style.top = d.body.scrollTop + D + 25 + 'px';
          });
        }
      }
      OOo.addEventListener(h, 'click', K);
      if (t.closeWithOverlay && !OOo.Browser.isMobile) {
        k.appendChild(n);
        n.onclick = K;
        g.onclick = K;
      }
      p.src = ' ';
      p.name = l;
      p.title = 'Comment Card';
      OOo.ariaHide(OOo.getDOMElems());
      q.appendChild(h);
      q.appendChild(p);
      k.appendChild(q);
      u.appendChild(k);
      g.appendChild(o);
      u.appendChild(g);
      d.body.appendChild(u);
      if (window.postMessage) {
        OOo.addEventListener(window, 'message', w);
      } else {
        B = setInterval(w, 500);
      }
      r.metrics.time2 = new Date().getTime();
      r.metrics.currentURL = location.href;
      if (r.constructCommentCardUrl) {
        p.setAttribute('src', OOo.appendOOForm(r, l));
      } else {
        A = OOo.appendOOForm(r, l);
        A.submit();
      }
    },
  });
  OOo.extend(OOo.Ocode, {
    postMessageHandler: function (f, d, g) {
      return function (a) {
        var b = 'https://secure.opinionlab.com',
          c;
        if (!g) {
          g = location;
        }
        if (
          (a && !(a.origin === b || a.origin.indexOf(d) !== 0)) ||
          (!a && g.hash.search('OL=') === -1)
        ) {
          return false;
        }
        c = a ? a.data : g.hash.split('=').pop();
        if (!a && location.hash) {
          location.hash = '';
        }
        f(c);
        return c;
      };
    },
  });
  OOo.Invitation = function (c) {
    var f = OOo.Browser;
    this.options = {
      tunnelCookie: 'oo_inv_tunnel',
      repromptTime: 604800,
      responseRate: 50,
      useBrowserStorage: false,
      repromptCookie: 'oo_inv_reprompt',
      promptMarkup: 'oo_inv_prompt.html',
      promptStyles: 'oo_inverstitial_style.css',
      percentageCookie: 'oo_inv_percent',
      pagesHitCookie: 'oo_inv_hit',
      cookieDomain: '',
      popupType: 'popunder',
      promptDelay: 0,
      neverShowAgainButton: false,
      loadPopupInBackground: false,
      truncatePrevCurrentMetrics: false,
      disablePrevCurrentMetrics: false,
      tealeafCookieName: 'TLTSID',
      monitor: { monitorWindow: 'oo_inv_monitor.html' },
      companySlogan: 'We value your opinion',
      beforePrompt: OOo.K,
      callBacks: { prompt: '', yesClick: '', noClick: '', closeClick: '' },
      inviteMarkup:
        "<div id='oo_invitation_prompt' aria-modal='true' role='dialog' aria-describedby='oo_invite_message'><div id='oo_invitation_company_logo'></div><div id='oo_invite_content'><p id='oo_invite_message'>After your visit, would you be willing to provide some quick feedback?<br /><br />(It will only take a minute)</p><p class='prompt_button'><button type='button' tabindex='0' id='oo_launch_prompt'>Yes<span class='screen_reader'>This will open a new window</span></button></p><p class='prompt_button'><button type='button' tabindex='0' id='oo_no_thanks'>No Thanks<span class='screen_reader'>Close dialog</span></button></p><p id='ol_invitation_brand_logo'></p></div><button type='button' id='oo_close_prompt' tabindex='0' aria-label='Close dialog'><div class='screen_reader'>Close dialog</div><span aria-hidden='true'>&#10006;</span></button></div><!--[if IE 8]><style>/* IE 8 does not support box-shadow */#oo_invitation_prompt #oo_invite_content { width: 400px; padding: 40px 49px 20px 49px; border: 1px solid #ccc; }</style><![endif]-->",
    };
    if (c.spa || OOo.spa) {
      OOo.addCustomSpaEvent();
    }
    if (c.disableMobile && f.isMobile) {
      return;
    }
    if (c.disableDesktop && f.isDesktop) {
      return;
    }
    if (c.disableNoniOS && f.isMobileNonIOS) {
      return;
    }
    this.popupShown = false;
    OOo.extend(this.options, c);
    var d = this.options,
      g = parseInt(OOo.readCookieOrStorage(d, d.pagesHitCookie), 10) || 0,
      k = OOo.removeCharacters(window.location.href),
      h = OOo.checkGateway('self-hosted'),
      o = OOo.removeCharacters(window.document.referrer),
      m,
      q = OOo.fcpEnvironment,
      n = OOo ? (OOo.fcp ? OOo.fcp : false) : false,
      l = OOo.createMetrics(),
      p = OOo.serialize(OOo.evalCustomVars(d)),
      u = false,
      r;
    OOo.Invitation.friendlyDomains = d.friendlyDomains || null;
    OOo.Invitation.instance = this;
    r = d.screenReaderMonitor
      ? d.screenReaderMonitor
      : 'This button will redirect this window to a comment card';
    var t = {
      weight: Number(OOo.readCookieOrStorage(d, 'oo_OODynamicRewrite_weight')),
      searchPattern: OOo.readCookieOrStorage(
        d,
        'oo_OODynamicRewrite_searchPattern'
      ),
      replacePattern: OOo.readCookieOrStorage(
        d,
        'oo_OODynamicRewrite_replacePattern'
      ),
    };
    OOo.eraseCookieOrStorage(d, 'oo_OODynamicRewrite_weight');
    OOo.eraseCookieOrStorage(d, 'oo_OODynamicRewrite_searchPattern');
    OOo.eraseCookieOrStorage(d, 'oo_OODynamicRewrite_replacePattern');
    if (
      !window.OOoDynamicRewrite ||
      window.OOoDynamicRewrite.weight < t.weight
    ) {
      window.OOoDynamicRewrite = t;
    }
    if (
      window.OOoDynamicRewrite &&
      'number' === typeof window.OOoDynamicRewrite.weight &&
      !isNaN(window.OOoDynamicRewrite.weight)
    ) {
      OOo.createCookieOrStorage(
        d,
        'oo_OODynamicRewrite_weight',
        window.OOoDynamicRewrite.weight,
        '',
        d.cookieDomain
      );
      if (window.OOoDynamicRewrite.searchPattern) {
        OOo.createCookieOrStorage(
          d,
          'oo_OODynamicRewrite_searchPattern',
          window.OOoDynamicRewrite.searchPattern,
          '',
          d.cookieDomain
        );
      }
      if (window.OOoDynamicRewrite.replacePattern) {
        OOo.createCookieOrStorage(
          d,
          'oo_OODynamicRewrite_replacePattern',
          window.OOoDynamicRewrite.replacePattern,
          '',
          d.cookieDomain
        );
      }
    }
    if (
      location.search.search('evs') !== -1 ||
      OOo.readCookieOrStorage(d, 'oo_evs_friendly') === 'yes'
    ) {
      OOo.eraseCookieOrStorage(d, 'oo_evs_friendly');
      d.loadPopupInBackground = true;
      this.launchPopup();
      OOo.createCookieOrStorage(
        d,
        d.repromptCookie,
        1,
        d.repromptTime === -1 ? 0 : d.repromptTime,
        d.cookieDomain
      );
    }
    setTimeout(
      function () {
        if (!window.oo_inv_monitor) {
          return;
        }
        if (d.area && location.href.search(d.area) === -1) {
          this.options.popupType = 'popup';
          this.launchPopup();
        } else if (d.goal && location.href.search(d.goal) !== -1) {
          window.oo_inv_monitor.close();
        }
      }.bind(this),
      1600
    );
    if (
      d.thirdPartyCookies &&
      OOo.checkThirdPartyCookies(d.thirdPartyCookies)
    ) {
      return;
    }
    if (!OOo.readCookieOrStorage(d, d.percentageCookie)) {
      OOo.createCookieOrStorage(
        d,
        d.percentageCookie,
        Math.random() > 1 - d.responseRate / 100 ? '1' : '0',
        '',
        d.cookieDomain
      );
    }
    if (typeof d.promptTrigger !== 'undefined') {
      if (d.promptTrigger instanceof RegExp) {
        if (!window.location.href.match(d.promptTrigger)) {
          return;
        }
      } else if (d.promptTrigger instanceof Array) {
        if (
          !OOo.checkTunnel(location.pathname, d.promptTrigger, d.tunnelCookie)
        ) {
          return;
        }
      }
    }
    if (d.spa || OOo.spa) {
      var x = this;
      x.queued = false;
      if (!OOo.readCookieOrStorage(d, d.repromptCookie)) {
        g = OOo.incrementPagesHit(d, g);
        OOo.assessPagesHit(g, x);
        var v = true;
        setTimeout(function () {
          v = false;
        }, 500);
      }
      OOo.addEventListener(window, 'oospachange', function () {
        l.currentURL = location.href;
        if (x.options.truncatePrevCurrentMetrics) {
          l.prev = OOo.truncateMetric(l.prev);
          l.currentURL = OOo.truncateMetric(l.currentURL);
        }
        if (x.options.disablePrevCurrentMetrics) {
          l.prev = '';
          l.currentURL = '';
        }
        if (!!window.oo_inv_monitor) {
          k = OOo.removeCharacters(window.location.href);
          o = OOo.removeCharacters(window.document.referrer);
          if (OOo.Invitation.friendlyDomains) {
            OOo.addEventListener(document, 'click', function (a) {
              OOo.Invitation.monitorLinks(a);
            });
          }
          window.oo_inv_monitor.postMessage(
            [
              [r, s.options.spa, s.options.referrerRewrite],
              l,
              [k, o],
              p,
              f,
              OOo.Invitation.friendlyDomains,
              window.innerHeight,
              window.OOoDynamicRewrite,
              u,
            ],
            m
          );
        }
        if (OOo.readCookieOrStorage(d, d.repromptCookie)) {
          return;
        }
        if (!v) {
          g = parseInt(OOo.readCookieOrStorage(d, d.pagesHitCookie), 10) || 0;
          g = OOo.incrementPagesHit(d, g);
        }
        if (
          location.search.search('evs') !== -1 ||
          OOo.readCookieOrStorage(d, 'oo_evs_friendly') === 'yes'
        ) {
          OOo.eraseCookieOrStorage(d, 'oo_evs_friendly');
          d.loadPopupInBackground = true;
          this.launchPopup();
          OOo.createCookieOrStorage(
            d,
            d.repromptCookie,
            1,
            d.repromptTime === -1 ? 0 : d.repromptTime,
            d.cookieDomain
          );
        }
        setTimeout(
          function () {
            if (!window.oo_inv_monitor) {
              return;
            }
            if (d.area && location.href.search(d.area) === -1) {
              this.options.popupType = 'popup';
              this.launchPopup();
            } else if (d.goal && location.href.search(d.goal) !== -1) {
              window.oo_inv_monitor.close();
            }
          }.bind(this),
          1600
        );
        if (OOo.readCookieOrStorage(d, d.repromptCookie)) {
          return;
        }
        if (
          d.thirdPartyCookies &&
          OOo.checkThirdPartyCookies(d.thirdPartyCookies)
        ) {
          return;
        }
        if (!OOo.readCookieOrStorage(d, d.percentageCookie)) {
          OOo.createCookieOrStorage(
            d,
            d.percentageCookie,
            Math.random() > 1 - d.responseRate / 100 ? '1' : '0',
            '',
            d.cookieDomain
          );
        }
        if (typeof d.promptTrigger !== 'undefined') {
          if (d.promptTrigger instanceof RegExp) {
            if (!window.location.href.match(d.promptTrigger)) {
              return;
            }
          } else if (d.promptTrigger instanceof Array) {
            if (
              !OOo.checkTunnel(
                location.pathname,
                d.promptTrigger,
                d.tunnelCookie
              )
            ) {
              return;
            }
          }
        }
        if (x.queued) {
          return;
        }
        OOo.assessPagesHit(g, x);
      });
    } else {
      if (!OOo.readCookieOrStorage(d, d.repromptCookie)) {
        g = OOo.incrementPagesHit(d, g);
        OOo.assessPagesHit(g, this);
      }
    }
    origin = document.location;
    if (n) {
      if (h) {
        m =
          origin.protocol +
          '//' +
          origin.host +
          (origin.port ? origin.port : '');
      } else {
        m = 'https://' + q + 'gateway.foresee.com';
      }
    } else {
      m =
        origin.protocol + '//' + origin.host + (origin.port ? origin.port : '');
    }
    var s = this;
    OOo.addEventListener(
      window,
      'message',
      function (b) {
        l.currentURL = location.href;
        if (s.options.referrerRewrite) {
          l.referer = OOo.referrerRewrite(s.options.referrerRewrite);
        }
        if (s.options.commentCardUrl) {
          l.referer = OOo.referrerRewrite(s.options.commentCardUrl);
        }
        if (s.options.truncatePrevCurrentMetrics) {
          l.prev = OOo.truncateMetric(l.prev);
          l.currentURL = OOo.truncateMetric(l.currentURL);
        }
        if (s.options.disablePrevCurrentMetrics) {
          l.prev = '';
          l.currentURL = '';
        }
        if (b.origin === m) {
          if (b.data === 'reconnect') {
            window.oo_inv_monitor = b.source;
            window.oo_inv_monitor.postMessage('connection forged', m);
            if (OOo.Invitation.friendlyDomains) {
              OOo.addEventListener(document, 'click', function (a) {
                OOo.Invitation.monitorLinks(a);
              });
            }
          }
          if (b.data === 'ready for data') {
            window.oo_inv_monitor.postMessage(
              [
                [r, s.options.spa, s.options.referrerRewrite],
                l,
                [k, o],
                p,
                f,
                OOo.Invitation.friendlyDomains,
                window.innerHeight,
                window.OOoDynamicRewrite,
                u,
                'connect',
              ],
              m
            );
          }
          if (b.data === 'focus') {
            window.blur();
          }
          if (b.data === 'monitor unload') {
            window.oo_inv_monitor = null;
          }
          if (b.data === 'heart') {
            if (!window.oo_inv_monitor) {
              window.oo_inv_monitor = b.source;
            }
            window.oo_inv_monitor.postMessage('beat', m);
          }
        }
      },
      false
    );
    OOo.addEventListener(window, 'unload', function () {
      if (window.oo_inv_monitor) {
        window.oo_inv_monitor.postMessage(
          [
            [r, s.options.spa, s.options.referrerRewrite],
            l,
            [k, o],
            p,
            f,
            OOo.Invitation.friendlyDomains,
            window.innerHeight,
            window.OOoDynamicRewrite,
            u,
            'disconnect',
          ],
          m
        );
        window.oo_inv_monitor.postMessage('parent going AWOL', m);
      }
    });
  };
  OOo.Invitation.monitorLinks = function (a) {
    var b = a || window.event,
      c = a.target || a.srcElement,
      f;
    if (
      c.nodeName != 'A' ||
      c.href.match(location.protocol + '//' + location.hostname) ||
      c.href.substr(0, 4) != 'http'
    )
      return;
    f = friendlyDomains.length;
    while (f--) {
      if (c.href.search(friendlyDomains[f]) != -1) {
        friendlyActive = true;
        f = 0;
      }
    }
    if (!friendlyActive) return;
    if (a.preventDefault) a.preventDefault();
    OOo.Invitation.navigateToFriendlyDomain(
      c.href.search(/\?/) == -1 ? c.href + '?evs' : c.href + 'evs'
    );
  };
  OOo.Invitation.notifyFriendlyLocationChange = function (a) {
    if (window.oo_inv_monitor) {
      OOo.createCookieOrStorage(
        opt,
        'oo_evs_friendly',
        'yes',
        '',
        opt.cookieDomain
      );
    }
  };
  OOo.Invitation.prototype = {
    getPrompt: function () {
      OOo.getPrompt.call(this);
    },
    showPrompt: function (a) {
      OOo.showPrompt.call(this, a, this.launchPopup);
    },
    launchPopup: function (a) {
      if (this.popupShown) {
        return;
      }
      this.popupShown = true;
      var b = a || window.event;
      if (typeof b !== 'undefined') {
        if (b.preventDefault && b.stopPropagation) {
          b.preventDefault();
          b.stopPropagation();
        } else {
          b.returnValue = false;
        }
      }
      var c = this.options,
        f = OOo ? (OOo.fcp ? OOo.fcp : false) : false,
        d = c.monitor.assetType ? c.monitor.assetType : 'static',
        g = c.monitor.pathToAssets
          ? c.monitor.pathToAssets
          : OOo.getPathToAssets(f, d),
        k = window.location.href,
        h =
          c.popupType === 'popup'
            ? 'https://secure.opinionlab.com/ccc01/comment_card.asp?'
            : g + c.monitor.monitorWindow + '?',
        o = [],
        m = c.asm ? [555, 500] : OOo.Browser.Chrome ? [400, 400] : [400, 350],
        q,
        n,
        l = OOo.createMetrics(),
        p = OOo.readCookieOrStorage(c, c.tealeafCookieName),
        u;
      if (c.commentCardUrl) {
        h =
          c.popupType === 'popup'
            ? c.commentCardUrl + '?'
            : g + c.monitor.monitorWindow + '?';
      } else {
        h =
          c.popupType === 'popup'
            ? 'https://secure.opinionlab.com/ccc01/comment_card.asp?'
            : g + c.monitor.monitorWindow + '?';
      }
      if (
        (c.clickTalePID && window.ClickTaleGetUID && window.ClickTaleGetSID) ||
        (window.ClickTaleGetPID &&
          window.ClickTaleGetUID &&
          window.ClickTaleGetSID)
      ) {
        p +=
          '|' +
          [
            c.clickTalePID || window.ClickTaleGetPID(),
            window.ClickTaleGetUID(),
            window.ClickTaleGetSID(),
          ].join('/');
      } else {
        p += '|undefined/undefined/undefined';
      }
      m = c.newWindowSize || m;
      u =
        'scrollbars=1,resizable=1,location=no,status=no,width=' +
        m[0] +
        ',height=' +
        m[1];
      if (c.referrerRewrite) {
        l.referer = OOo.referrerRewrite(c.referrerRewrite);
      }
      if (c.commentCardUrl) {
        l.referer = OOo.referrerRewrite(c.commentCardUrl);
      }
      l.currentURL = location.href;
      if (c.truncatePrevCurrentMetrics) {
        l.prev = OOo.truncateMetric(l.prev);
        l.currentURL = OOo.truncateMetric(l.currentURL);
      }
      if (c.disablePrevCurrentMetrics) {
        l.prev = '';
        l.currentURL = '';
      }
      if (c.thirdPartyCookies) {
        OOo.setThirdPartyCookies(c.thirdPartyCookies);
      }
      h += this.buildInviteUrlParam(c, l, p);
      if (h.match(/\?/g).length === 2) h = h.replace(/\?([^?]*)$/, '&$1');
      OOo.dispatchOOEvent('OL_CC_Launch');
      this.popup = q = window.open(h, 'OnlineOpinionInvitation', u);
      if (!c.loadPopupInBackground && OOo.$('#oo_container')) {
        OOo.hidePrompt(b);
      }
    },
    killPrompt: function (a) {
      var b = a || window.event;
      if (
        this.options.callBacks &&
        typeof this.options.callBacks.noClick === 'function'
      ) {
        this.options.callBacks.noClick();
      }
      OOo.createCookieOrStorage(
        this.options,
        this.options.repromptCookie,
        1,
        157680000,
        opt.cookieDomain
      );
      OOo.hidePrompt(b);
    },
    buildInviteUrlParam: function (a, b, c) {
      var f, d;
      if (a.efm && a.popupType == 'popup') {
        f = OOo.convertToEFMParams(a, b) + '&type=Invitation';
      } else {
        f = OOo.toQueryString(b) + '&type=Invitation';
        if (a.customVariables || a.efm || a.digitalBehaviorAnalytics) {
          f +=
            '&customVars=' +
            encodeURIComponent(OOo.serialize(OOo.evalCustomVars(a)));
        }
        d = a.legacyVariables;
        f += '&custom_var=' + OOo.createLegacyVars(d, c);
        if (a.asm) {
          f += '&asm=2';
        }
      }
      return f;
    },
  };
  OOo.extend(OOo.Invitation, {
    navigateToFriendlyDomain: function (a) {
      if (a && a.indexOf('evs') > -1) {
        location.href = encodeURIComponent(a);
      }
    },
  });
  OOo.Waypoint = function (a) {
    var b = OOo.Browser;
    if (a.disableMobile && b.isMobile) {
      return;
    }
    if (a.disableDesktop && b.isDesktop) {
      return;
    }
    if (a.disableNoniOS && b.isMobileNonIOS) {
      return;
    }
    this.options = {
      waypointMarkup: 'oo_waypoint.html',
      companySlogan: 'Give us feedback',
      companyLogo: 'waypoint_logo.png',
      linkFocus: true,
      categories: {
        website: {
          tealeafCookieName: 'TLTSID',
          waypointIcon: 'waypoint_icon.png',
          buttonText: 'Website',
        },
        store: {
          referrerRewrite: {
            searchPattern: /:\/\//,
            replacePattern: '://store.',
          },
          tealeafCookieName: 'TLTSID',
          waypointIcon: 'waypoint_icon.png',
          buttonText: 'Store',
        },
        product: {
          referrerRewrite: {
            searchPattern: /:\/\//,
            replacePattern: '://product.',
          },
          tealeafCookieName: 'TLTSID',
          waypointIcon: 'waypoint_icon.png',
          buttonText: 'Product',
        },
        other: {
          referrerRewrite: {
            searchPattern: /:\/\//,
            replacePattern: '://other.',
          },
          tealeafCookieName: 'TLTSID',
          waypointIcon: 'waypoint_icon.png',
          buttonText: 'Other',
        },
      },
      wpmarkup:
        "<div id='oo_waypoint_prompt' aria-modal='true' role='dialog' aria-describedby='oo_waypoint_message'><div id='oo_waypoint_company_logo'></div><div id='oo_waypoint_content'><p id='oo_waypoint_message'>Select a category</p><p id='waypoint_icons'></p><p id='ol_waypoint_brand_logo'></p></div><button id='oo_waypoint_close_prompt' tabindex='0' aria-label='Close dialog'><div class='screen_reader'>Close dialogue</div><span aria-hidden='true'>&#10006;</span></button></div><!--[if IE 8]><style>/* IE 8 does not support box-shadow */#oo_waypoint_prompt #oo_waypoint_content { width: 400px; padding: 40px 49px 20px 49px; border: 1px solid #ccc; }</style><![endif]-->",
    };
    OOo.extend(this.options, a);
    var c = this.options,
      f = c.categories;
    if (c.spa || OOo.spa) {
      OOo.addCustomSpaEvent();
    }
    for (var d in f) {
      if (f.hasOwnProperty(d)) {
        if (typeof c.categories[d].oCode === 'object') {
          var g = {};
          g[d] = new OOo.Ocode(f[d].oCode);
          OOo.extend(OOo.Waypoint, g);
        }
      }
    }
    OOo.extend(OOo.Waypoint, {
      getWaypoint: function () {
        this.getWaypoint();
      }.bind(this),
    });
  };
  OOo.Waypoint.prototype = {
    getWaypoint: function () {
      OOo.getWaypoint.call(this);
    },
    showWaypoint: function (a) {
      OOo.showWaypoint.call(this, a);
    },
    killWaypoint: function (a) {
      var b = a || window.event;
      OOo.hideWaypoint(b);
    },
  };
  OOo.extend(OOo, {
    appendWaypoint: function (c) {
      var f = OOo.$('#' + c);
      if (!!f) {
        if (!OOo.Browser.isMobile) {
          OOo.addEventListener(
            f,
            'click',
            function (a) {
              var b = a || window.event;
              if (b.preventDefault && b.stopPropagation) {
                b.preventDefault();
                b.stopPropagation();
              } else {
                b.returnValue = false;
              }
              OOo.Waypoint.getWaypoint();
            },
            false
          );
          OOo.addEventListener(
            f,
            'keydown',
            function (a) {
              var b = a || window.event;
              if (b.keyCode !== 13) {
                return;
              }
              if (b.preventDefault && b.stopPropagation) {
                b.preventDefault();
                b.stopPropagation();
              } else {
                b.returnValue = false;
              }
              OOo.Waypoint.getWaypoint();
            },
            false
          );
        }
      }
    },
  });
  OOo.extend(OOo, {
    appendWaypointMobile: function (c, f) {
      var d = 0;
      var g = f || 2;
      if (typeof c === 'string') var c = OOo.$('#' + c);
      if (!!c) {
        if (OOo.Browser.isMobile) {
          if (
            'ontouchstart' in window &&
            'ontouchend' in window &&
            'ontouchmove' in window
          ) {
            OOo.addEventListener(
              c,
              'touchstart',
              function (a) {
                d++;
              },
              false
            );
            OOo.addEventListener(
              c,
              'touchend',
              function (a) {
                var b = a || window.event;
                if (b.preventDefault && b.stopPropagation) {
                  b.preventDefault();
                  b.stopPropagation();
                  if (d >= g) {
                    OOo.Waypoint.getWaypoint();
                    d = 0;
                  }
                } else {
                  b.returnValue = false;
                }
              },
              false
            );
            OOo.addEventListener(
              c,
              'touchmove',
              function (a) {
                d = 0;
              },
              false
            );
          } else {
            OOo.addEventListener(
              c,
              'click',
              function (a) {
                var b = a || window.event;
                if (b.preventDefault && b.stopPropagation) {
                  b.preventDefault();
                  b.stopPropagation();
                } else {
                  b.returnValue = false;
                }
                OOo.Waypoint.getWaypoint();
              },
              false
            );
          }
        }
      }
    },
  });
  return OOo;
});
